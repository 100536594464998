import { Component, OnInit } from '@angular/core';
import { Mapa, MapaFacade } from '@funceme/core-monitor';
import { zip } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { emptyMapa } from 'libs/core-monitor/src/lib/state/mapa/mapa.model';
import { environment } from '@env/environment';

@Component({
  selector: 'app-comparacoes-mapas',
  templateUrl: './comparacoes-mapas.component.html',
  styleUrls: ['./comparacoes-mapas.component.css']
})
export class ComparacoesMapasComponent implements OnInit {

  public mapa1: Mapa = emptyMapa;
  public mapa2: Mapa = emptyMapa;
  public selectedMapa1;
  public selectedMapa2;
  public mes1;
  public ano1;
  public mes2;
  public ano2;
  public allPeriodos = [];
  public lastMap: Mapa;

  public loading: boolean = false;

  public routePriority: boolean = false;
  public filesEndpoint = environment.filesEndpoint;

  public lastMapSubscription;
  public subscriptions;

  constructor(
    private mapaFacade: MapaFacade,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.subscriptions = this.mapaFacade.loading$.subscribe(value => this.loading = value);

    this.mapaFacade.selectMapa(null);
    this.mapaFacade.loadMapaRecente();

    this.lastMapSubscription = this.mapaFacade.currentMapa$.subscribe(value => {
      if (!value.ano) return;
      this.lastMap = value;
      this.setAllPeriodos();

      this.selectedMapa1 = this.allPeriodos[0];
      this.selectedMapa2 = this.allPeriodos[1];

      let split1 = this.selectedMapa1.split('/');
      let split2 = this.selectedMapa2.split('/');

      [this.mes1, this.ano1] = split1;
      [this.mes2, this.ano2] = split2;

      const routeParams: Object = this.route.snapshot.queryParams;
      
      if (routeParams.hasOwnProperty('mes1') && routeParams.hasOwnProperty('ano1')) {
        this.routePriority = true;
        this.selectedMapa1 = routeParams['mes1']+"/"+routeParams['ano1'];
        this.mes1 = Number(routeParams['mes1']);
        this.ano1 = Number(routeParams['ano1']);
        this.mapaFacade.pickMapa(routeParams['mes1'], routeParams['ano1']);
      } else 
        this.mapaFacade.pickMapa(this.mes1,this.ano1);

      if (routeParams.hasOwnProperty('mes2') && routeParams.hasOwnProperty('ano2')) {
        this.routePriority = true;
        this.selectedMapa2 = routeParams['mes2']+"/"+routeParams['ano2'];
        this.mes2 = Number(routeParams['mes2']);
        this.ano2 = Number(routeParams['ano2']);
        this.mapaFacade.pickMapa(routeParams['mes2'], routeParams['ano2']);
      } else 
        this.mapaFacade.pickMapa(this.mes2,this.ano2);

      // if (!this.mapa1.nome)
      //   this.mapaFacade.pickMapa(this.mes1,this.ano1)

      // if (!this.mapa2.nome)
      //   this.mapaFacade.pickMapa(this.mes2,this.ano2)
    })


    this.subscriptions.add(
      this.mapaFacade.currentMapa$.subscribe(mapa => {
        if (!mapa.nome) return;

        if (mapa.mes == this.mes1 && mapa.ano == this.ano1)
          this.mapa1 = mapa;
        if (mapa.mes == this.mes2 && mapa.ano == this.ano2)
          this.mapa2 = mapa;

        if (!this.routePriority)
          this.syncRoute();
        else 
          this.routePriority = false;

      })
    );
  }

  ngOnInit() {  
    
  }

  changedMapa1(event){
    this.selectedMapa1 = event.value;
    [this.mes1,this.ano1] = event.value.split('/');
    this.mapaFacade.pickMapa(this.mes1,this.ano1);
    this.mapaFacade.pickMapa(null,null);
  }

  changedMapa2(event){
    this.selectedMapa2 = event.value;
    [this.mes2,this.ano2] = event.value.split('/');
    this.mapaFacade.pickMapa(this.mes2,this.ano2);
    this.mapaFacade.pickMapa(null,null);
  }

  private syncRoute() 
  {
    this.router.navigate([], {
      queryParams:{
        mes1: this.mapa1.mes,
        ano1: this.mapa1.ano,
        mes2: this.mapa2.mes,
        ano2: this.mapa2.ano
      },
      replaceUrl: true
    });
  }

  setAllPeriodos() {
    let year = this.lastMap.ano;
    let month = this.lastMap.mes;
    let c = 100;
    while (year != 2014 || month != 6){
      this.allPeriodos.push(month+"/"+year);
      month--;
      if (month == 0) {
        month = 12;
        year--;
      }
    }

    this.lastMapSubscription.unsubscribe()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StateModule } from './state/state.module';
import { NotificationsService } from './notifications/notifications.service';
import { UfService } from './state/uf/uf.service';
import { MapaService } from './state/mapa/mapa.service';
import { MapaMudancaService } from './state/mapa-mudanca/mapa-mudanca.service';


@NgModule({
  providers: [
    UfService,
    MapaService,
    MapaMudancaService,
    NotificationsService
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    StateModule
  ],
})
export class CoreDataModule {}

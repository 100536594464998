import { Action } from '@ngrx/store';
import { MapaMudanca } from './mapa-mudanca.model';

export enum MapaMudancaActionTypes {
  MapaMudancasAction = '[MapaMudancas] Action',
  LoadMapaMudancas = '[MapaMudancas] Load Data',
  MapaMudancasLoaded = '[MapaMudancas] Data Loaded',
  MapaMudancaSelected = '[MapaMudancas] Selected'
}

export class MapaMudancas implements Action {
  readonly type = MapaMudancaActionTypes.MapaMudancasAction;
}

export class LoadMapaMudancas implements Action {
  readonly type = MapaMudancaActionTypes.LoadMapaMudancas;
  constructor() { }
}

export class MapaMudancasLoaded implements Action {
  readonly type = MapaMudancaActionTypes.MapaMudancasLoaded;
  constructor(public payload: MapaMudanca[]) { }
}

export class MapaMudancaSelected implements Action {
  readonly type = MapaMudancaActionTypes.MapaMudancaSelected;
  constructor(public payload: string) { }
}

export type MapaMudancaActions = MapaMudancas
  | LoadMapaMudancas
  | MapaMudancasLoaded
  | MapaMudancaSelected
;
export const environment = {
  production: true,
  filesEndpoint: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/',
  //apiEndpoint: 'https://apimsbr.funceme.br/'
  apiEndpoint: 'https://apimsbr.ana.gov.br/'
};

export const authConfig = {
  client_id: 50,
  client_secret: 'ueuWlHUrDb6rPz0mMHlai3BSDZbQru4XbUYoaIAM',
  guest_username: 'monitor_r@bot.br',
  guest_password: '#monitor_R#',
  route_default: 'mapa'
}
export const version = "5.0.19";

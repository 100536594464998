import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { MapaActions, MapaActionTypes } from './mapa.actions';
import { Mapa } from './mapa.model';

/**
 * Interface to the part of the Store containing MapaState
 * and other information related to MapaData.
 */
export interface MapaState extends EntityState<Mapa> {
  selectedId: number;
  loading: boolean;
}

export const adapter: EntityAdapter<Mapa> = createEntityAdapter<Mapa>({
  selectId: (mapa: Mapa) => mapa.id,
});

export const initialState: MapaState = adapter.getInitialState({
  selectedId: null,
  loading: false
});

export function mapaReducer(state = initialState, action: MapaActions): MapaState {

  switch (action.type) {
    case MapaActionTypes.MapaSelected: {
      return Object.assign({}, state, { selectedId: action.payload, loading: false });
    }

    case MapaActionTypes.LoadMapas: {
      return Object.assign({}, state, { loading: true });
    }

    case MapaActionTypes.PickMapa: {
      return Object.assign({}, state, { loading: true });
    }

    case MapaActionTypes.LoadLastMapa: {
      return Object.assign({}, state, { loading: true });
    }

    case MapaActionTypes.MapasLoaded: {
      return adapter.addAll(action.payload, Object.assign({}, state, { loading: false }));
    }

    case MapaActionTypes.MapaAdded: {
      return adapter.upsertOne(action.payload, Object.assign({}, state, { selectedId: action.payload.id, loading: false }));
    }
    
    default:
      return state;
  }
}

export const getSelectedMapaId = (state: MapaState) => state.selectedId;
export const getLoadingMapa = (state: MapaState) => state.loading;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of mapa ids
export const selectMapaIds = selectIds;

// select the dictionary of mapa entities
export const selectMapaEntities = selectEntities;

// select the array of mapa
export const selectAllMapas = selectAll;

// select the total mapa count
export const selectMapaTotal = selectTotal;

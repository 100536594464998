import { ActionReducerMap} from '@ngrx/store';
import * as fromUf from './uf/uf.reducer';
import * as fromConfig from './config/config.reducer';
import * as fromMapa from './mapa/mapa.reducer';
import * as fromMapaMudanca from './mapa-mudanca/mapa-mudanca.reducer';

// Single Source of Truth
export interface AppState {
    uf: fromUf.UfState,
    config: fromConfig.ConfigState,
    mapa: fromMapa.MapaState,
    mapaMudanca: fromMapaMudanca.MapaMudancaState
} 

export const reducers: ActionReducerMap<AppState> = { 
    uf: fromUf.ufReducer,
    config: fromConfig.configReducer,
    mapa: fromMapa.mapaReducer,
    mapaMudanca: fromMapaMudanca.mapaMudancaReducer
};
<mat-card style="margin: 10px 0; ">


  <mat-card-content>
    <div class="container">


        <a class="btn-primary">INSTITUIÇÃO CENTRAL</a>
        <p class="text-justify">A Instituição Central (IC) do Monitor de Secas é a Agência Nacional de Água e Saneamento Básico
          – ANA. Sua função no Programa é coordenar toda a rotina mensal de elaboração do mapa, promovendo a integração entre as
          diversas instituições parceiras que atuam nos papéis de Autores, Validadores, Observadores e Provedores de informação.
          Em outras palavras, a ANA trabalha para garantir a atualização e publicação do mapa mensal de monitoramento de secas,
          que é produzido a partir da interação entre todos os atores envolvidos no processo.</p>
        <p class="text-justify">Para desempenhar essa missão, a ANA conta com um apoio especial da FUNCEME (Fundação Cearense de
          Meteorologia e Recursos Hídricos), sobretudo no que diz respeito ao gerenciamento do banco de dados do Programa;
          geração de indicadores e produtos de apoio ao traçado; manutenção do site; e realização de estudos e pesquisas visando
          à melhoria dos processos e aperfeiçoamento do Programa.</p>

        <a class="btn-primary">AUTOR</a>
        <p class="text-justify">O Autor é o responsável pelo traçado dos mapas, que é feito no Quantum Gis (QGIS). Para o
          traçado, são analisados o máximo de dados e informações disponíveis em diferentes escalas temporais, como indicadores
          de seca e produtos de apoio (anomalia de precipitação, índice de vegetação etc.), de forma a representar a situação de
          seca mais realista possível em cada estado.</p>

        <a class="btn-primary">VALIDADOR</a>
        <p class="text-justify">O Validador é o responsável por confirmar ou sugerir alterações ao traçado do mapa proposto pelo
          autor, baseando-se em evidências que atestem a severidade da seca que o seu estado está passando. Vale destacar que,
          as sugestões de alterações do mapa feita pelo(s) validador(es), restringem-se apenas ao seu estado.</p>

        <a class="btn-primary">OBSERVADOR</a>
        <p class="text-justify">O Observador é o responsável por relatar os impactos decorrentes das secas nas diversas
          localidades (municípios) dos estados. Normalmente, são pessoas que fazem trabalho de campo e que estão ligadas às
          instituições estaduais com grande capilaridade (Ex: Defesas Civis, Órgãos de Extensão Rural, etc).</p>

        <object zoom="150%" data="https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/documentos/autores_validadores.pdf" type="application/pdf" width="100%" height="1000px">
          <p>Seu navegador não suporta PDFs. Você pode <a href="https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/documentos/autores_validadores.pdf">baixá-lo aqui</a> em vez disso.</p>
        </object>


    </div>

  </mat-card-content>
</mat-card>

import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { MapaMudanca } from './mapa-mudanca.model';
import { MapaMudancaService } from './mapa-mudanca.service';

import {
  LoadMapaMudancas, MapaMudancasLoaded,
  MapaMudancaActionTypes
} from './mapa-mudanca.actions';
import { MapaMudancaState } from './mapa-mudanca.reducer';
import { NotificationsService } from '../../notifications/notifications.service';
import { MapaMudancaFacade } from './mapa-mudanca.facade';

@Injectable({providedIn: 'root'})
export class MapaMudancaEffects
{
  //@Effect() effect$ = this.actions$.ofType(MapaMudancasActionTypes.MapaMudancasAction);

  @Effect()
  loadMapaMudancas$ = this.dataPersistence.fetch(MapaMudancaActionTypes.LoadMapaMudancas, {
    run: (action: LoadMapaMudancas, state: any) => {


      return this.mapaService
        .all()
        .pipe(
          map((res: String[]) => {
            let mapas: MapaMudanca[] = [];
            const files = JSON.parse(res['file']);

            files.forEach(element => {
              const pathParts = element.split('/');
              const filename = pathParts[pathParts.length - 1];
              const body = filename.split('.')[0];
              const bodyParts = body.split('_');

              if (bodyParts.length == 5)
              {
                let id = bodyParts[3].slice(0, 7) + '_' + bodyParts[4]
                let data = bodyParts[3];
                let path = element;
                let intervalo = bodyParts[4];
                let regiao = bodyParts[2];
                mapas.push({
                  id: id,
                  data: data,
                  path: path,
                  intervalo: intervalo,
                  regiao: regiao
                });
              }


            });

            return new MapaMudancasLoaded(mapas);
          }
        ));
    },

    onError: (action: LoadMapaMudancas, error) => {
      this.notifyError.emit(error.statusText);
    }
  });

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<MapaMudancaState>,
    private mapaService: MapaMudancaService,
    private mapaFacade: MapaMudancaFacade,
    private notifyError: NotificationsService
  ) {}
}

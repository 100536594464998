export interface MapaMudanca {
    id: string;
    data: string;
    path: string;
    regiao: string;
    intervalo: string;
}

export const emptyMapaMudanca: MapaMudanca = {
    id: null,
    data: null,
    path: null,
    regiao: null,
    intervalo: null
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapCoreComponent } from './map-core/map-core.component';

@NgModule({
  declarations: [
    MapCoreComponent
  ],
  exports: [],
  imports: [
    CommonModule
  ],
  providers: [
  ]
})
export class MapModule { }

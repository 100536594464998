<mat-card style="padding: 0px;">
    <mat-card-header>
        <mat-card-title>O Monitor de Secas</mat-card-title>

    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content>
        <div class="container text-justify">

            <h4>O que é o Monitor de Secas?</h4>

            <p>O Monitor de Secas é um processo de acompanhamento regular e periódico da situação da seca no Brasil, cujos resultados consolidados são divulgados por meio do Mapa do Monitor de Secas. O mapa é mensal e apresenta a situação de seca nas unidades federativas no mês anterior, além das áreas que ficaram livres do fenômeno. </p>
            <p>Nos locais com seca, o Monitor mostra, com uma escala de cores, o grau de severidade da seca, que pode ser: fraca, moderada, grave, extrema ou excepcional. Linhas em negrito delimitam as três tipologias de seca possíveis: curto, longo ou curto&longo prazo, fazendo referência aos indicadores de seca de curto prazo (1, 3, 4 e 6 meses) e de longo prazo (9, 12, 18 e 24 meses), que são considerados para a elaboração do mapa.</p>

            <h4>Objetivos:</h4>

              <ol>
                <li>
                  Integrar o conhecimento técnico e científico já existente em diferentes instituições estaduais e federais para alcançar um entendimento comum sobre as condições de seca, como: sua severidade, a evolução espacial e no tempo, e seus impactos sobre os diferentes setores envolvidos;
                </li>
                <li>
                  Traduzir dados e informações que estão dispersos nas instituições parceiras em produtos utilizáveis como ferramentas de tomada de decisão, de modo a fortalecer os mecanismos de Monitoramento, Previsão e Alerta Precoce de secas.
                </li>
              </ol>

              <h4>O Início:</h4>

              <p>O Monitor de Secas do Brasil teve início em 2014 como um projeto-piloto focado no semiárido, usando como primeiro recorte o Nordeste do País. Na época, a Região estava sendo assolada por uma seca muito intensa iniciada em 2012 e que era apontada como a mais grave dos últimos 100 anos.</p>

              <p>No começo dos trabalhos, a iniciativa teve apoio financeiro do Banco Mundial e de parcerias internacionais, como a Comissão Nacional da Água do México (Conagua) e o Centro Nacional de Mitigação de Secas dos Estados Unidos (National Drought Mitigation Center).</p>

              <p>Desde 2017, o Monitor conta com a Agência Nacional de Águas como instituição central do Programa, sendo responsável pela articulação institucional entre todos os parceiros, a coordenação do processo de elaboração dos mapas mensais, os treinamentos das equipes de trabalho e a implementação de melhorias e avanços metodológicos.</p>

              <p>O desenvolvimento de um Monitor de Secas no Brasil veio ao encontro de uma necessidade histórica de se estabelecer um monitoramento contínuo e um gerenciamento adequado das secas no País e representa o primeiro de muitos passos necessários para uma radical e urgente mudança de abordagem – da gestão emergencial e reativa à uma gestão proativa que,  reconhecendo a seca como um evento que se instala lenta e progressivamente, permite enfrentá-la desde o seu estágio inicial, definindo ‘gatilhos’ para lidar com cada grau de severidade.</p>

              <h4>O Futuro:</h4>

              <p>Por se tratar de um mecanismo de integração da informação de nível federal, estadual e municipal nas áreas de meteorologia, recursos hídricos e agricultura, o Monitor de Secas tem potencial de se tornar um novo patamar na história de preparação e enfrentamento das secas no Brasil, servindo como subsídio para a tomada de decisões nas três esferas governamentais.</p>

          </div>
        <app-origem-monitor></app-origem-monitor>
        <p></p>
        <p><iframe allowfullscreen="" frameborder="0" height="480" scrolling="no" src="https://www.youtube.com/embed/wUwxLlGsAew"
          width="100%"></iframe></p>
          <p></p>
        <app-expansao-monitor></app-expansao-monitor>
    </mat-card-content>
</mat-card>

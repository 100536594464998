<mat-form-field>
    <mat-chip-list #chipList aria-label="Uf selection">
        <mat-chip
            *ngFor="let uf of selectedUfs"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(uf)">
            {{ (uf)? uf.nome : '' }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
            placeholder="UF"
            #ufInput
            [formControl]="ufCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let uf of filteredUfs$ | async" [value]="uf.id">
        {{ uf.nome }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import * as MapaMudancaActions from './mapa-mudanca.actions';
import { MapaMudancaState } from './mapa-mudanca.reducer';
import { selectAllMapaMudancas, selectCurrentMapaMudanca, selectLoadingMapaMudanca } from './mapa-mudanca.selectors';

@Injectable({ providedIn: 'root' })
export class MapaMudancaFacade {
  
  allMapaMudancas$ = this.store.pipe(select(selectAllMapaMudancas));
  currentMapaMudanca$ = this.store.pipe(select(selectCurrentMapaMudanca));
  loading$ = this.store.pipe(select(selectLoadingMapaMudanca));

  constructor(private store: Store<MapaMudancaState>, private actions$: ActionsSubject) {}

  selectMapaMudanca(mapaMudancaId: string) {
    this.store.dispatch(new MapaMudancaActions.MapaMudancaSelected(mapaMudancaId));
  }

  loadMapaMudancas() {
    this.store.dispatch(new MapaMudancaActions.LoadMapaMudancas());
  }
}
<mat-card style="padding: 0px;" >


    <mat-divider></mat-divider>

    <mat-card-content class="" >
      <div></div>



      <mat-tab-group style="min-height: 390px;">
        <mat-tab label="PARCEIROS" >

          <mat-tab-group >
            <mat-tab label="ESTADUAIS">


              <mat-card *ngFor="let parceiro of parceirosEstaduais" class="parceiro">
                <mat-card-content (click)="navigate(parceiro.url)">
                  <div class="parceiro-img">
                    <div style="width:100%">
                      <img src={{parceiro.logo}}>
                    </div>
                  </div>
                  <div class="name"><strong>{{parceiro.nome}}</strong></div>
                  <div class="url"><strong>Site:</strong> {{parceiro.url}}</div>
                </mat-card-content>
              </mat-card>


            </mat-tab>
            <mat-tab label="FEDERAIS">

              <mat-card *ngFor="let parceiro of parceirosFederais" class="parceiro">
                <mat-card-content (click)="navigate(parceiro.url)">
                  <div class="parceiro-img">
                    <div style="width:100%">
                      <img src={{parceiro.logo}}>
                    </div>
                  </div>
                  <div class="name"><strong>{{parceiro.nome}}</strong></div>
                  <div class="url"><strong>Site:</strong> {{parceiro.url}}</div>
                </mat-card-content>
              </mat-card>

            </mat-tab>
            <mat-tab label="INTERNACIONAIS" style="justify-content: left;">

              <mat-card *ngFor="let parceiro of parceirosInternacionais" class="parceiro">
                <mat-card-content (click)="navigate(parceiro.url)">
                  <div class="parceiro-img">
                    <div style="width:100%">
                      <img src={{parceiro.logo}}>
                    </div>
                  </div>
                  <div class="name"><strong>{{parceiro.nome}}</strong></div>
                  <div class="url"><strong>Site:</strong> {{parceiro.url}}</div>
                </mat-card-content>
              </mat-card>

            </mat-tab>
          </mat-tab-group>

        </mat-tab>

        <mat-tab label="EQUIPE TÉCNICA">
          <app-pdf-viewer></app-pdf-viewer>
        </mat-tab>

        <!-- <mat-tab label="IDENTIDADE VISUAL">
          <app-identidade-visual></app-identidade-visual>
        </mat-tab> -->

      </mat-tab-group>

    </mat-card-content>
</mat-card>

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map } from 'rxjs/operators';
import { isString } from 'util';
import { Uf } from '../../../state/uf/uf.model';
import { UfFacade } from '../../../state/uf/uf.facade';

@Component({
  selector: 'app-uf-select',
  templateUrl: './uf-select.component.html',
  styleUrls: ['./uf-select.component.css']
})
export class UfSelectComponent implements OnInit {
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public ufCtrl = new FormControl();

  public filteredUfs$: Observable<Uf[]>;
  public selectedUfs: Uf[] = [];
  public allUfs: Uf[] = [];

  @ViewChild('ufInput') ufInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private ufFacade: UfFacade
  ) { 
    this.filteredUfs$ = this.ufFacade.allUfs$.pipe(
      map(ufs => this.allUfs = ufs)
    );

    this.ufFacade.currentUfs$.subscribe(
      currentUfs => this.selectedUfs = currentUfs
    );

    this.ufCtrl.valueChanges.subscribe(
      search => {
        this.filteredUfs$ = this.filteredUfs$.pipe(
          map(() => search && isString(search)? this._filter(search) : this.allUfs.slice()),
          map(ufs => ufs.filter(uf => this.selectedUfs.indexOf(uf) === -1))
        )
      }
    );
  }

  ngOnInit() {
    
  } 

  remove(uf: Uf): void {
    this.ufFacade.unselectUf(uf.id);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let selectedUf = this.allUfs.find(uf => uf.id == event.option.value);

    if (this.selectedUfs.indexOf(selectedUf) !== -1) 
      return;

    this.ufFacade.selectUf(selectedUf.id);

    this.ufInput.nativeElement.value = '';
  }

  private _filter(search: string): Uf[] {
    const filterValue = search.toLowerCase();
    return this.allUfs.filter(uf => uf.nome.toLowerCase().indexOf(filterValue) === 0);
  }
}
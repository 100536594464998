<mat-sidenav-container class="example-container" (backdropClick)="close()">
  <mat-sidenav #sidenav (keydown.escape)="close()" disableClose>

    <button class="close-button" mat-button (click)="close()">
        <mat-icon style="color: #4E0001; padding: 0px 8px">clear</mat-icon>
    </button>

    <mat-divider></mat-divider>

      <button  mat-button (click)="link('mapa')">Mapas</button>

      <button mat-button [matMenuTriggerFor]="menuAbout">Sobre o Monitor<mat-icon>arrow_drop_down</mat-icon></button>
    <mat-menu #menuAbout="matMenu" yPosition="below">
      <button mat-menu-item routerLink="/o-monitor-de-secas">O Monitor de Secas</button>
      <!-- <button mat-menu-item routerLink="/a-origem-do-monitor">A Origem do Monitor</button> -->
      <button mat-menu-item routerLink="/x-anos">10 anos</button>
      <button mat-menu-item routerLink="/quem-somos">Quem somos</button>
      <!-- <button mat-menu-item routerLink="/aplicativos">Aplicativos</button> -->
      <!-- <button mat-menu-item routerLink="/calendario-de-mapas">Calendários de Mapas</button> -->
      <!-- <button mat-menu-item routerLink="/expansao-do-monitor">Expansão do Monitor</button> -->
      <!-- <button mat-menu-item routerLink="/identidade-visual">Identidade Visual</button> -->
      <!-- <button mat-menu-item routerLink="/instituicoes-parceiras">Instituições Parceiras</button> -->
      <button mat-menu-item routerLink="/publicacoes">Publicações</button>
      <button mat-menu-item routerLink="/tabela-de-classificacao">Tabela de Severidade de Seca</button>
    </mat-menu>

      <button mat-button (click)="link('/calendario-de-mapas')">
        Calendário
      </button>

      <button mat-button [matMenuTriggerFor]="menuDadosMapas">Dados e Mapas<mat-icon>arrow_drop_down</mat-icon></button>
      <mat-menu #menuDadosMapas="matMenu" yPosition="below">
        <button mat-menu-item (click)="link('mapa')">Mapa mais recente</button>
        <button mat-menu-item (click)="link('alteracoes-mensais')">Alterações mensais</button>
        <button mat-menu-item (click)="link('animacoes')">Animações de mapas</button>
        <button mat-menu-item (click)="link('comparacoes-mapas')">Comparações de mapas</button>
        <button mat-menu-item (click)="link('dados-sig')">Dados SIG</button>
        <button mat-menu-item (click)="link('dados-tabulares')">Dados tabulares</button>
      </mat-menu>

      <button  mat-button (click)="link('perguntas-frequentes')">Perguntas frequentes</button>

      <button mat-button (click)="link('/contato')">
        Contato
      </button>

    </mat-sidenav>

    <mat-sidenav-content>
      <div class="container-root">
        <app-header [sidenav]="sidenav" class="layout-header BgVerdeGrad mat-elevation-z2" ></app-header>
        <div class="layout-content">
            <app-navigation></app-navigation>
            <router-outlet></router-outlet>
        </div>
        <app-footer class="layout-footer"></app-footer>
      </div>
    </mat-sidenav-content>
</mat-sidenav-container>


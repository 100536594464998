import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { Mapa } from './mapa.model';
import { MapaService } from './mapa.service';

import {
  LoadMapas, MapasLoaded,
  MapaActionTypes,
  PickMapa,
  MapaSelected,
  MapaAdded,
  LoadLastMapa,
} from './mapa.actions';
import { MapaState } from './mapa.reducer';
import { NotificationsService } from '../../notifications/notifications.service';
import { MapaFacade } from './mapa.facade';
import { throws } from 'assert';

@Injectable({providedIn: 'root'})
export class MapaEffects 
{
  //@Effect() effect$ = this.actions$.ofType(MapasActionTypes.MapasAction);

  @Effect()
  loadMapas$ = this.dataPersistence.fetch(MapaActionTypes.LoadMapas, {
    run: (action: LoadMapas, state: any) => {

      if(Object.keys(state.mapa.entities).length > 0){
        let allMapas;

        this.mapaFacade.allMapas$.subscribe( Mapas => allMapas = Mapas )
        return new MapasLoaded(allMapas);
      }

      return this.mapaService
        .all()
        .pipe(
          map((res: Mapa[]) => new MapasLoaded(res['data']['list']))
        )
    },

    onError: (action: LoadMapas, error) => {
      this.notifyError.emit(error.statusText);
    }
  });

  @Effect()
  pickMapa$ = this.dataPersistence.fetch(MapaActionTypes.PickMapa, {
    run: (action: PickMapa, state: any) => {

      if(!action.payload.mes)
      return new MapaSelected(null); 

      let _mapa: Mapa = null;

      Object.keys(state.mapa.entities).forEach(key => {
        if (state.mapa.entities[key].mes == action.payload.mes && state.mapa.entities[key].ano == action.payload.ano)
          _mapa = state.mapa.entities[key];
      });    

      if (_mapa && this.isValid(_mapa))
        return new MapaSelected(_mapa.id); 
      
      return this.mapaService
        .pick(action.payload.mes, action.payload.ano)
        .pipe(
          map((res) => {
            if (!(res && res['data'] && res['data']['list'] && res['data']['list'].length == 1))
              return new MapaSelected(state.mapa.selectedId);

            const _mapa: Mapa = res['data']['list'][0];
            _mapa.queue_in = res['meta']['cache']['queue_in'];
            _mapa.expires_in = res['meta']['cache']['expires_in'];
            _mapa.cached_at = new Date(res['meta']['cache']['cached_at']['date']);

            return new MapaAdded(_mapa);
          })
        );
    },

    onError: (action: PickMapa, error) => {
      this.notifyError.emit(error.statusText);
    }
  });

  @Effect()
  loadLastMapa$ = this.dataPersistence.fetch(MapaActionTypes.LoadLastMapa, {
    run: (action: LoadLastMapa, state: any) => {

      return this.mapaService
        .loadLast()
        .pipe(
          map((res) => {
            if (!(res || res['data'] || res['data']['list'] || res['data']['list'].length == 1))
              return;

            const _mapa: Mapa = res['data']['list'][0];
            _mapa.queue_in = res['meta']['cache']['queue_in'];
            _mapa.expires_in = res['meta']['cache']['expires_in'];
            _mapa.cached_at = new Date(res['meta']['cache']['cached_at']['date']);

            return new MapaAdded(_mapa);
          })
        )
    },

    onError: (action: LoadLastMapa, error) => {
      this.notifyError.emit(error.statusText);
    }
  });

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<MapaState>,
    private mapaService: MapaService,
    private mapaFacade: MapaFacade,
    private notifyError: NotificationsService
  ) {}

  private isValid(mapa: Mapa) {
    if (mapa.cached_at == null)
      return false;

    let lowerTime = (mapa.queue_in < mapa.expires_in)? mapa.queue_in : mapa.expires_in;

    let now = new Date;
    let time = new Date(mapa.cached_at.getTime());
    time.setSeconds(time.getSeconds() + lowerTime);

    return now.getTime() < time.getTime();
  }
}

<mat-card style="padding: 0px;">
    <mat-card-header>
        <mat-card-title>PERGUNTAS FREQUENTES</mat-card-title>

    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content>
        <ol>
            <li class="btn-primary">
              <div><h4>O que é o monitor de secas?</h4></div>

              <div>
                <p>O Monitor de Secas é um acompanhamento (<span title="Monitorar significa acompanhar periodicamente um fenômeno, medindo como ele varia. Por exemplo, pode-se monitorar o nível de uma represa. Pode-se monitorar as notas de uma criança na escola. E pode-se monitorar a seca. ">monitoramento</span>)
                  regular e periódico da situação da seca. Os resultados consolidados deste monitoramento são
                  divulgados por meio de um mapa: o <a routerLink="/dados-sig">Mapa do Monitor de Secas</a>.</p>

                <p>O mapa é elaborado mensalmente, e acompanha como a seca está progredindo (melhorando ou piorando), apresentando da forma mais fiel possível uma fotografia atual da situação da seca no território monitorado.</p>


              </div>
            </li>
            <li>
              <div><h4>O mapa do monitor de secas é uma previsão de secas?</h4></div>

              <div>
                <p><strong>NĀO!</strong> O mapa do Monitor mostra a situação <strong>atual</strong> da seca.</p>

                <p>Monitoramento é <strong>muito diferente</strong> de previsão! Enquanto a <strong>previsão</strong> olha para
                  o <strong>futuro (prevê),</strong> o monitoramento olha para o <strong>passado e para o presente</strong>.</p>
              </div>
            </li>
            <li>
              <div><h4>Como se faz o monitoramento das secas?</h4></div>

              <div>
                <p>O <strong>mapa do Monitor de secas</strong> é produzido mensalmente por um grupo de
                  instituições e pessoas de diversos <a routerLink="/instituicoes-parceiras">Estados e do Governo Federal</a>, de forma
                  <strong>colaborativa</strong> e <strong>voluntária</strong>.</p>


                <p>O Provedor é a instituição que compartilha dados de sua rede de monitoramento com a Instituição Central (IC) do Monitor de Secas.</p>

                <p>O Autor é o responsável pelo traçado dos mapas, que é feito no Quantum Gis (QGIS). Para o traçado, são analisados o máximo de dados e informações disponíveis em diferentes escalas temporais, como indicadores de seca e produtos de apoio (anomalia de precipitação, índice de vegetação etc.), de forma a representar a situação de seca mais realista possível em cada estado.</p>

                <p>O Validador é o responsável por confirmar ou sugerir alterações no ao traçado do mapa proposto pelo autor, baseando-se em evidências que atestem a severidade da seca que o seu estado está passando. Vale destacar que, as sugestões de alterações do mapa feita pelo(s) validador(es), restringem-se apenas ao seu estado.</p>

                <p>O Observador é o responsável por relatar os impactos decorrentes das secas nas diversas localidades (municípios) dos estados. Normalmente, são pessoas que fazem trabalho de campo e estão ligadas a instituições estaduais com grande capilaridade (ex.: Defesas Civis, Órgãos de Extensão Rural, etc.).</p>

                <p>A Instituição Central é a responsável por coordenar e supervisionar o processo de elaboração dos mapas do Monitor de Secas, garantindo sua atualização e publicação mensal.  Esta INSTITUICAO CENTRAL é a <a href="http://www.ana.gov.br" target="_blank">ANA</a> (Agência Nacional de Águas e Saneamento Básico), que conta com o apoio da <a href="http://www.funceme.br/" target="_blank" >FUNCEME</a> (Fundação Cearense de Meteorologia) na operacionalização mensal.</p>


              </div>
            </li>
            <li>
              <div><h4>Onde fica o monitor de secas? Posso visitar?</h4></div>

              <div>
                <p>O monitoramento é feito em conjunto por diversos atores, que desempenham papeis distintos, mas cada um trabalha em sua própria sede. Eles se comunicam regularmente por e-mail, telefone e/ou videoconferência. Portanto,<strong> não existe</strong> uma central de monitoramento da seca. Se você quiser visitar o local onde se produz o mapa do Monitor de Secas, procure um dos autores do seu Bloco Regional (Nordeste, Sul/Sudeste, Centro-Oeste/Norte) ou a Instituição Central.</p>
              </div>
            </li>
            <li>
              <div><h4>Como é produzido o Mapa do Monitor de Secas?</h4></div>

              O mapa é produzido em 4 etapas.
              <ol style="list-style-type: lower-roman;">
                  <ul>
                    <li><strong>Etapa 1. Coleta de Dados.</strong> A Instituição Central recolhe, junto aos institutos de monitoramento uma série de dados do mês anterior (chuva, umidade do solo, temperatura etc.), processa esses dados utilizando linguagem de programação e consolida todos os dados em um projeto QGis (software) e, após, envia para o(s) autor(es). A cada mês, uma instituição de cada região fica responsável pelo mapa (sāo os autores-coordenadores do mapa naquele mês).</li>
                    <li>
                      <p><strong>Etapa 2. Autoria do Mapa.</strong> O(s) Autores se reúnem, processam e analisam esses dados em conjunto, e traçam um mapa-rascunho (R0). Após, é feita uma reunião de autoria onde se discute os mapas R0’s e, posteriormente, elaborado um outro rascunho (de consenso), o mapa Rascunho-1 (R1), o primeiroa ser enviado para os validadores estaduais.</p>
                    </li>
                  </ul>

                  <p><img alt="" src="assets/img/etapas.v2.png" style="display: block; margin-left: auto; margin-right: auto; width: 100%; max-width: 534px;"></p>

                  <ul>
                    <li>
                      <p><strong>Etapa 3. Validação do Mapa.</strong>&nbsp;Com o R1 pronto, entram em ação os Validadores. Os validadores têm um (1) dia (dois turnos de trabalho – 1 manhã e 1 tarde) para validar o R1, concordando ou discordando do mapa proposto, a partir das evidências que levantaram. Essa validação é feita por meio de um formulário (recebido e entregue por e-mail), no qual o validador justifica sua concordância ou discordância do mapa. Após analisar os formulários enviados, os autores promovem ajustes no traçado e produzem uma segunda versão do mapa-Rascunho (R2) que também segue para validação. Nesta etapa, por ser mais rápida, os validadores dispõem de apenas 1 turno de trabalho (manhã ou tarde) para manifestarem se estão de acordo com o mapa proposto.</p>
                    </li>
                    <li>
                      <p><strong>Etapa 4. Publicação.</strong> Encerrada a etapa de validação do mapa, uma versão final é consolidada, junto com a Síntese do Traçado, a qual descreve resumidamente as principais mudanças na situação de seca dos estados monitorados, em relação ao mês anterior.</p>
                    </li>
                  </ul>
                  &nbsp;

                    <div class="row" style="display: flex; justify-content: center; ">
                      <div class="col"><img alt="" src="assets/img/mapa2.v2.png" style="display: block; margin-left: auto; margin-right: auto; max-width: 450px; width: 80%;"></div>
                      <div class="col"><img alt="" src="assets/img/mapa1.v2.png" style="display: block; margin-left: auto; margin-right: auto; max-width: 450px; width: 80%;"></div>
                    </div>

                  <ul>
                    <li>&nbsp;Veja acima a diferença entre o Rascunho-1 do Mapa do Monitor (à esquerda) e o Mapa Validado
                      (Final) (à direita). Perceba como as linhas, e as regiões coloridas estão diferentes no mapa esquerdo e
                      direito.</li>
                  </ul>

                  <p><img alt="" src="assets/img/etapas.v2.png" style="display: block; margin-left: auto; margin-right: auto; width: 100%; max-width: 534px;"></p>
              </ol>
            </li>
            <li>
              <div><h4>Que dados sāo utilizados para monitorar a seca e produzir o mapa? Quem manda os dados para o Monitor de Secas?</h4></div>


              <div>
                <p>Existem muitas instituições que fazem monitoramento de chuva (quanto choveu em cada lugar), de níveis de
                  reservatórios, de umidade de solo e outras informações que são importantes para obtermos um retrato o mais
                  completo possível da seca em cada região.</p>

                <p>Atualmente, esses dados são enviados pelas seguintes instituições: Agência Nacional de Águas (ANA), Instituto Nacional de Meteorologia (INMET), Instituto Nacional de Pesquisas Espaciais (INPE), NESDIS e CPC/NOAA, além de todos os parceiros estaduais que possuem rede de monitoramento e assumiram o papel de provedores de informação.</p>


              </div>
            </li>
            <li>
              <div><h4>O que significam as cores do mapa? E as linhas?</h4></div>

              <div>
                <p>As cores do mapa indicam as categorias de seca. Quanto mais escuro, mais seco está.</p>

                <p>As categorias de seca descrevem os efeitos (impactos) da seca nas lavouras, na disponibilidade de agua, nas
                  pastagens etc.</p>

                <p>Veja na tabela abaixo os níveis de seca e a descrição do que acontece (impacto) em cada nível de seca.</p>
              </div>

              <table class="table-severidade" cellpadding="2" cellspacing="0" width="100%">
                <tbody>

                  <tr>
                    <td class="width-cols">
                      <p><strong>Categoria</strong></p>
                    </td>
                    <td class="width-cols">
                      <p><strong>Descrição</strong></p>
                    </td>
                    <td class="width-cols">
                      <p><strong>Percentil</strong></p>
                    </td>
                    <td class="width-cols">
                      <p><strong>SPI/SPEI</strong></p>
                    </td>
                    <td class="width-cols">
                      <p><strong>Recorrência</strong></p>
                    </td>
                    <td>
                      <p><strong>Impactos Possíveis</strong></p>
                    </td>
                  </tr>

                  <tr class="row-s0">
                    <td>
                        <p><strong>S0</strong></p>
                    </td>
                    <td>
                        <p>Seca Fraca</p>
                    </td>
                    <td>
                        <p>30 %til</p>
                    </td>
                    <td>
                      <p>-0,5 a -0,79</p>
                    </td>
                    <td>
                      <p>Uma vez a cada <span style="white-space: nowrap;">2-5 anos</span></p>
                    </td>
                    <td>
                        <p>Entrando em seca: veranico de curto prazo diminuindo plantio, crescimento de culturas ou pastagem. Saindo de seca: alguns déficits hídricos prolongados, pastagens ou culturas não completamente recuperadas.</p>
                    </td>
                </tr>


                <tr class="row-s1">
                  <td >
                      <p><strong>S1</strong></p>
                  </td>
                  <td>
                      <p>Seca Moderada</p>
                  </td>
                  <td>
                      <p>20 %til</p>
                  </td>
                  <td>
                    <p>-0,8 a -1,29</p>
                  </td>
                  <td>
                    <p>Uma vez a cada <span style="white-space: nowrap;">5-10 anos</span></p>
                  </td>
                  <td>
                      <p>Alguns danos às culturas, pastagens; córregos, reservatórios ou poços com níveis baixos, algumas faltas de água em desenvolvimento ou iminentes; restrições voluntárias de uso de água solicitadas.</p>
                  </td>
              </tr>

              <tr class="row-s2">
                <td>
                    <p><strong>S2</strong></p>
                </td>
                <td>
                    <p>Seca Grave</p>
                </td>
                <td>
                    <p>10 %til</p>
                </td>
                <td>
                  <p>-1,3 a -1,59</p>
                </td>
                <td>
                  <p>Uma vez a cada <span style="white-space: nowrap;">10-20 anos</span></p>
                </td>
                <td>
                    <p>Perdas de cultura ou pastagens prováveis; escassez de água comuns; restrições de água impostas.</p>
                </td>
              </tr>

            <tr class="row-s3">
                <td>
                    <p><strong>S3</strong></p>
                </td>
                <td>
                    <p>Seca Extrema</p>
                </td>
                <td>
                    <p>5 %til</p>
                </td>
                <td>
                  <p>-1,6 a -1,99</p>
                </td>
                <td>
                  <p>Uma vez a cada <span style="white-space: nowrap;">20-50 anos</span></p>
                </td>
                <td>
                    <p>Grandes perdas de culturas / pastagem; escassez de água generalizada ou restrições.</p>
                </td>
            </tr>

            <tr class="row-s4">
                <td>
                    <p><strong>S4</strong></p>
                </td>
                <td>
                    <p>Seca Excepcional</p>
                </td>
                <td>
                    <p>2 %til</p>
                </td>
                <td>
                  <p><-2</p>
                </td>
                <td>
                  <p>Uma vez a cada <span style="white-space: nowrap;">50-100 anos</span></p>
                </td>
                <td>
                    <p>Perdas de cultura / pastagem excepcionais e generalizadas; escassez de água nos reservatórios, córregos e poços de água, criando situações de emergência.</p>
                </td>
            </tr>

                </tbody>
              </table>


              <p>Tabela: Estágios de seca, ou categorias, as quais definem a intensidade de seca no mapa do Monitor. Fonte: Adaptado
                do National Drought Mitigation Center, Lincoln, Nebraska, U.S</p>

              <div>
                <ul>
                  <li>
                    <p><strong>Existem áreas brancas no Mapa. O que isso quer dizer?</strong></p>

                    <div>
                      <p>Áreas brancas são aquelas sem ocorrência de seca relativa, ou seja, onde a condição é normal considerando a climatologia local e o histórico de dados no mês de referência do mapa. Lembrando que o Monitor faz monitoramento de secas e não de estiagem, que tem um conceito diferente (<a target="_blank" href="https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/data/publicacoes/Glossario_v3.pdf">Clique aqui</a> para entender a diferença). Isso significa que a área branca pode estar passando por um período de estiagem, sem, contudo, apresentar uma seca.</p>
                    </div>
                  </li>
                </ul>
              </div>

              <div>
                <ul>
                  <li>
                    <p><strong>As letras no mapa</strong></p>

                    <div>
                      <p>Você deve ter notado que as letras <strong>C</strong> e <strong>L</strong> estão espalhadas em alguns
                        pontos do mapa. Elas não estão ai por acaso.</p>

                        <p>Essas letras indicam o <strong>tipo de impacto da seca</strong>. Pode ser que este impacto seja de <strong>Curto Prazo (C)</strong>, de <strong>Longo Prazo (L)</strong> ou de <strong>Curto e Longo Prazo (CL)</strong>. Isso tem relação com a duração do fenômeno e com os indicadores usados para o traçado dos mapas.</p>
                    </div>
                  </li>
                </ul>
              </div>

              <div>
                <ul>
                  <li>
                    <p><strong>As linhas do mapa</strong></p>

                    <div>
                        <p>As linhas em negrito do mapa separam regiões com tipologias de seca distintas. Secas de curto prazo (C) normalmente estão associadas a secas em processo de evolução e/ou intensificação e a déficits de precipitação recentes, que estejam atuando por até 8 meses na região. Secas de curto e longo prazo (CL) são “o pior cenário” de seca em termos de escala temporal, significando que a condição de seca está estabelecida há mais de 9 meses naquela área, tendendo a persistir por, pelo menos, alguns meses a mais. Secas de longo prazo (L) são aquelas em processo regressivo e/ou atenuação, relacionadas a déficits de precipitação mais antigos (em 12, 18 ou 24 meses).</p>

                      <img alt="" src="assets/img/mapa1.v2.png" style="display: block; margin-left: auto; margin-right: auto; max-width: 434px;">
                    </div>
                  </li>
                </ul>
              </div>

              <div>
                <ul>
                  <li>
                    <div>
                      <p><strong>Existe uma correspondência entre o grau de severidade da seca (S0 a S4) e a tipologia de impactos (C, L, ou CL)?</strong></p>
                    </div>

                        <p><strong>Nāo</strong>. Em qualquer categoria de severidade de seca mapeada – Fraca (S0), Moderada (S1), Grave (S2), Extrema (S3) ou Excepcional (S4), dependendo do tempo de duração do fenômeno e o seu nível de intensidade, a tipologia poderá ser de curto, longo ou curto&longo prazo.</p>
                  </li>
                </ul>
              </div>
            </li>
            <li>
                <div><h4>Para que serve o Monitor de Secas?</h4></div>

              <div>
                <p>O Monitor de Secas possibilita um acompanhamento contínuo em escala mensalda situação da seca em cada estado monitorado. Além de apresentar de forma fácil e visual as áreas com existência (área colorida) ou ausência do fenômeno (área branca), o Monitor também indica o grau de severidade da seca (dividido em 5 categorias) e  a tipologia dos impactos associados (C, CL ou L), que decorrem da duração/permanência do fenômeno sobre as áreas afetadas. </p>

                <p>Além disso, com a sequência de mapas mensais, é possível acompanhar o surgimento, desaparecimento, evolução ou involução do fenômeno da seca em todo território nacional.</p>

                <p>Embora o mapa do Monitor de Secas seja um produto de diagnóstico da situação e não forneça informações de previsão, se for examinado em conjunto com produtos de previsão climática sazonal, por exemplo, pode ajudar a perceber tendências para o futuro e, com isso, melhorar a tomada de decisões por parte dos gestores públicos. Assim, saímos de uma lógica de gestão emergencial e reativa para uma gestão proativa e de planejamento e preparação.</p>
              </div>
            </li>
            <li>
              <div><h4>O que diferencia o monitor de secas e seu mapa de outros indicadores ou produtos?</h4></div>

              <div>
                <p>A primeira diferença é que o <strong>Monitor de Secas</strong> não é um mapa produzido automaticamente por um software ou programa de computador, levando em consideração somente cálculos numéricos. Não basta saber quanto, onde e quando choveu para saber se um local está sob os efeitos da seca. Isso porque pode, por exemplo, ter chovido bastante em um local e isso resultar em uma safra acima da média, mas ainda assim não haver água suficiente nos reservatórios. Desse modo, se olharmos somente a quantidade de chuva (índice de precipitação), teremos um retrato incompleto da situação naquele local.</p>

                <p>Por isso, o Monitor de Secas junta dados meteorológicos, hidrológicos e agrícolas para retratar a seca nessas 3 dimensões.</p>

                <p>Mas não basta ter os dados, é preciso saber interpretá-los. Em regiões como o caso do semiárido Nordestino, sabemos que há períodos em que normalmente não chove durante meses. Esse período sem chuva é normal e parte do clima da região. Na hora de traçar o Mapa do Monitor de Secas, esse tipo de informação se torna relevante e é levada em consideração pelos autores do mapa. Se assim não fosse, um período que normalmente é seco no semiárido poderia receber uma classificação errada, levando gestores a tomarem decisões equivocadas.</p>
              </div>
            </li>
            <li>
              <div><h4>O Monitor de Secas é um equipamento? O Monitor de secas é um programa de computador (software)?</h4></div>

              <div>
                <p>Não, o monitor de secas não é um equipamento ou programa de computador. Os autores utilizam computadores e programas para receber e processar os dados, mas a análise, a interpretação dos dados, o desenho do mapa (rascunhos e mapa final) e validação são feitos por pessoas, profissionais especializados e capacitados para isso.</p>
              </div>
            </li>
            <li>
              <div><h4>Qual é a precisão do monitor de secas? Ele consegue dizer exatamente como está a seca no meu
                    município?</h4></div>

              <div>
                <p>O monitor de secas é tão preciso quanto permitem os dados de monitoramento e validação enviados para os autores. Ou seja, quanto melhor a rede de monitoramento, a qualidade dos dados e quanto mais robusta a rede de validação e observação, mais preciso será o mapa. Porém, a escala do mapa é regional e por isso, somente as linhas de delimitação geopolítica dos estados aparecem, o que já facilita bastante aos usuários do mapa. É comum encontrar mapas com o recorte estadual e as divisas municipais nos sites das instituições estaduais parceiras que, normalmente, produzem boletins mensais de acompanhamento da situação de seca.</p>
              </div>
            </li>
          </ol>

    </mat-card-content>
</mat-card>

<mat-card style="padding: 0px;">
    <mat-card-header>
        <mat-card-title>Tabela de Classificação de Severidade da Seca</mat-card-title>

    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content>
        <h4>Resumo de descrição dos mapas</h4>
        <p style="text-align: justify">
          <strong> S0-S4:</strong> O Monitor de Secas apresenta uma legenda que categoriza as regiões afetadas pela seca, classificando-as de S0 (menos intensa) a S4 (mais intensa). As secas fraca (S0) e moderada (S1) possuem, estatisticamente, um tempo de retorno menor, sendo, portanto, mais frequentes no mapa. Por outro lado, as demais categorias de seca (S2, S3 e S4) enquadram-se como eventos mais “raros”, com tempos de retorno superiores a 10 anos.
          <br /><br />
          <strong>C e L:</strong> Essas letras evidenciam de que maneira a seca gera impactos ao longo do tempo, afetando as esferas social, ambiental e econômica.
          <br /><br />

          <strong style="margin-left: 20px;">C</strong> = Seca de Curto Prazo, normalmente atuando por menos de 8 meses (ex. impactos na agricultura)<br />
          <strong  style="margin-left: 20px;">L</strong> = Seca de Longo Prazo, normalmente atuando por mais de 9 meses (ex. impactos na hidrologia e ecologia)

        </p>

        <div class="dirtfade"></div>
        <h4>Classificação de Severidade da Seca</h4>

        <table class="table-severidade" cellpadding="2" cellspacing="0" width="100%">
          <tbody>

            <tr>
              <td class="width-cols">
                <p><strong>Categoria</strong></p>
              </td>
              <td class="width-cols">
                <p><strong>Descrição</strong></p>
              </td>
              <td class="width-cols">
                <p><strong>Percentil</strong></p>
              </td>
              <td class="width-cols">
                <p><strong>SPI/SPEI</strong></p>
              </td>
              <td class="width-cols">
                <p><strong>Recorrência</strong></p>
              </td>
              <td>
                <p><strong>Impactos Possíveis</strong></p>
              </td>
            </tr>

            <tr class="row-s0">
              <td>
                  <p><strong>S0</strong></p>
              </td>
              <td>
                  <p>Seca Fraca</p>
              </td>
              <td>
                  <p>30 %til</p>
              </td>
              <td>
                <p>-0,5 a -0,79</p>
              </td>
              <td>
                <p>Uma vez a cada <span style="white-space: nowrap;">2-5 anos</span></p>
              </td>
              <td>
                  <p>Entrando em seca: veranico de curto prazo diminuindo plantio, crescimento de culturas ou pastagem. Saindo de seca: alguns déficits hídricos prolongados, pastagens ou culturas não completamente recuperadas.</p>
              </td>
          </tr>


          <tr class="row-s1">
            <td >
                <p><strong>S1</strong></p>
            </td>
            <td>
                <p>Seca Moderada</p>
            </td>
            <td>
                <p>20 %til</p>
            </td>
            <td>
              <p>-0,8 a -1,29</p>
            </td>
            <td>
              <p>Uma vez a cada <span style="white-space: nowrap;">5-10 anos</span></p>
            </td>
            <td>
                <p>Alguns danos às culturas, pastagens; córregos, reservatórios ou poços com níveis baixos, algumas faltas de água em desenvolvimento ou iminentes; restrições voluntárias de uso de água solicitadas.</p>
            </td>
        </tr>

        <tr class="row-s2">
          <td>
              <p><strong>S2</strong></p>
          </td>
          <td>
              <p>Seca Grave</p>
          </td>
          <td>
              <p>10 %til</p>
          </td>
          <td>
            <p>-1,3 a -1,59</p>
          </td>
          <td>
            <p>Uma vez a cada <span style="white-space: nowrap;">10-20 anos</span></p>
          </td>
          <td>
              <p>Perdas de cultura ou pastagens prováveis; escassez de água comuns; restrições de água impostas.</p>
          </td>
        </tr>

      <tr class="row-s3">
          <td>
              <p><strong>S3</strong></p>
          </td>
          <td>
              <p>Seca Extrema</p>
          </td>
          <td>
              <p>5 %til</p>
          </td>
          <td>
            <p>-1,6 a -1,99</p>
          </td>
          <td>
            <p>Uma vez a cada <span style="white-space: nowrap;">20-50 anos</span></p>
          </td>
          <td>
              <p>Grandes perdas de culturas / pastagem; escassez de água generalizada ou restrições.</p>
          </td>
      </tr>

      <tr class="row-s4">
          <td>
              <p><strong>S4</strong></p>
          </td>
          <td>
              <p>Seca Excepcional</p>
          </td>
          <td>
              <p>2 %til</p>
          </td>
          <td>
            <p><-2</p>
          </td>
          <td>
            <p>Uma vez a cada <span style="white-space: nowrap;">50-100 anos</span></p>
          </td>
          <td>
              <p>Perdas de cultura / pastagem excepcionais e generalizadas; escassez de água nos reservatórios, córregos e poços de água, criando situações de emergência.</p>
          </td>
      </tr>

          </tbody>
        </table>
        <p style="font-size: 12px; font-style: italic; margin-bottom: 25px;">
            Estágios de seca, ou categorias, as quais definem a intensidade de seca no mapa do Monitor. Fonte: Adaptado do National Drought Mitigation Center, Lincoln, Nebraska, U.S.
        </p>

    </mat-card-content>
</mat-card>

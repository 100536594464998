import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContatoMonitorService } from 'libs/core-monitor/src/lib/services/contato-monitor.service';
import { NotificationsService } from 'libs/core-monitor/src/lib/notifications/notifications.service';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css']
})
export class ContatoComponent implements OnInit {


  public loading: boolean = false;
  public registerForm: FormGroup;
  public submitted = false;
  public nome;
  public email;
  public telefone;
  public mensagem;
  private captchaResponse = null;

  constructor(
    private formBuilder: FormBuilder,
    private contatoService: ContatoMonitorService,
    private notify: NotificationsService
  ) {
    this.contatoService.serviceListener$.subscribe(
      value => {
        this.loading = false;
        // console.log(value);
        if (!value.failure)
          notify.emit("Mensagem enviada com sucesso!");
        else
          notify.emit("Serviço temporariamente indisponível!");
      }
    )
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      nome: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      telefone: ['', [Validators.required]],
      mensagem: ['', [Validators.required]]
    });
  }

  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;
    // console.log(this.captchaResponse)
    if (this.registerForm.invalid || !this.captchaResponse) {
      // console.log("error");
      return false;
    }
    this.loading = true;
    this.contatoService.get(this.registerForm.value);

    this.onReset();
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
  }

}

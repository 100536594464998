import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class RpcDadosTabularesService {

  public base_url = environment.apiEndpoint;
  private urn = 'rpc/v1/dados-tabulares-monitor';

  private tipo_area: number;
  private area: string;

  private serviceListener = new Subject<any>();
  public  serviceListener$ = this.serviceListener.asObservable();

  constructor(private http: HttpClient) { }

  public forTipoArea(tipo_area: number) 
  {
      this.tipo_area = tipo_area;
      return this;
  }

  public forArea(area: string)
  {
      this.area = area;
      return this;
  }

  public get(callback?, errorHandler?): void
  {
      this.http.get(this.base_url + this.urn + '?tipo_area=' + this.tipo_area +
        '&area=' + this.area).subscribe((response) => {
          if (callback)
              callback(response);
          this.serviceListener.next(response);   
      }, (error) => {
          if (errorHandler)
              errorHandler(error);
      });
  }
}

import * as fromMapaMudanca from './mapa-mudanca.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { emptyMapaMudanca } from './mapa-mudanca.model';

export const selectMapaMudancaState = createFeatureSelector<fromMapaMudanca.MapaMudancaState>('mapaMudanca');

export const selectedMapaMudancaId = createSelector(
  selectMapaMudancaState,
  fromMapaMudanca.getSelectedMapaMudancaId
);

export const selectMapaMudancaEntities = createSelector(
  selectMapaMudancaState,
  fromMapaMudanca.selectMapaMudancaEntities
);

export const selectAllMapaMudancas = createSelector(
  selectMapaMudancaState,
  fromMapaMudanca.selectAllMapaMudancas
);

export const selectCurrentMapaMudancaId = createSelector(
  selectMapaMudancaState,
  fromMapaMudanca.getSelectedMapaMudancaId
);

export const selectLoadingMapaMudanca = createSelector(
  selectMapaMudancaState,
  fromMapaMudanca.getLoadingMapaMudanca
);

export const selectCurrentMapaMudanca = createSelector(
  selectMapaMudancaState,
  selectCurrentMapaMudancaId,
  (mapaMudancaEntities, mapaMudancaId) => {
    return mapaMudancaId ? mapaMudancaEntities.entities[mapaMudancaId] : emptyMapaMudanca;
  }
);

<mat-card style="padding: 0px;">
        <mat-card-header>
            <mat-card-title>Upload de arquivo</mat-card-title>
        </mat-card-header>
    
        <mat-divider></mat-divider>
    
        <mat-progress-bar mode="indeterminate" [class.show]="loading" [class.hide]="!loading"></mat-progress-bar>
    
        <mat-card-content>
            <mat-card id="mat-card-form">
                <form [formGroup]="registerForm" class="form-horizontal">
                        <div class='choose-file'>
                            <input formControlName="file" [ngClass]="{ 'is-invalid': submitted && f.file.errors }" placeholder="File" class="file-input" type="file">
                        </div>
                    <div *ngIf="submitted && f.file.errors" class="invalid-feedback">
                        <div *ngIf="f.file.errors.required">Arquivo é requerido</div>
                    </div>
    
                    <button mat-raised-button color="primary" (click)="onSubmit()">Enviar</button>
                    <button mat-raised-button color="basic" (click)="onReset()">Limpar</button>
                    (Tamanho máximo 2MB)
                </form>
            </mat-card>
        </mat-card-content>
    </mat-card>
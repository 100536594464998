<mat-card style="padding: 0px;">
    <mat-card-header>
        <mat-card-title>Comparações de mapas</mat-card-title>

    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-progress-bar mode="indeterminate" [class.show]="loading" [class.hide]="!loading"></mat-progress-bar>

    <mat-card-content>

        <div class="container">

            <div class="item">
                <div class="selector">
                    <mat-form-field>
                        <mat-select [value]="selectedMapa2" (selectionChange)="changedMapa2($event)">
                            <mat-option *ngFor="let periodo of allPeriodos" [value]="periodo">
                                {{ periodo }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="item-monitor">
                    <img *ngIf="mapa2.path" src="{{filesEndpoint}}{{ mapa2.path }}" class="img-responsive" />
                </div>

            </div>
            
            <div class="item">
                <div class="selector">
                    <mat-form-field>
                        <mat-select [value]="selectedMapa1" (selectionChange)="changedMapa1($event)">
                            <mat-option *ngFor="let periodo of allPeriodos" [value]="periodo">
                                {{ periodo }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="item-monitor">
                    <img *ngIf="mapa1.path" src="{{filesEndpoint}}{{ mapa1.path }}" class="img-responsive" />
                </div>

            </div>
        </div>

    </mat-card-content>
</mat-card>

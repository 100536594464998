import { Component, OnInit } from '@angular/core';
import { Mapa, MapaFacade } from '@funceme/core-monitor';
import { emptyMapa } from 'libs/core-monitor/src/lib/state/mapa/mapa.model';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'app-animacoes-mapas',
  templateUrl: './animacoes-mapas.component.html',
  styleUrls: ['./animacoes-mapas.component.css']
})
export class AnimacoesMapasComponent implements OnInit {

  public loading: boolean = false;
  public mes = 7;
  public ano = 2014;
  public stop = false;
  private interval = 1;
  private dataMapaMaisRecente: Date = null;
  private dataMapaMaisAntigo = new Date('2014-07-31');
  private finalMes;
  private finalAno;
  private myTimer;

  public filesEndpoint = environment.filesEndpoint; 
  public routePriority: boolean = false;

  public mapa: Mapa = emptyMapa;
  public lastMap: Mapa;

  public lastMapSubscription;
  public subscriptions;

  constructor(
    private mapaFacade: MapaFacade,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.subscriptions = this.mapaFacade.loading$.subscribe(value => this.loading = value);

    this.mapaFacade.selectMapa(null);
    this.mapaFacade.loadMapaRecente();

    this.lastMapSubscription = this.mapaFacade.currentMapa$.subscribe(value => {
      if (!value.ano) return;
      this.lastMap = value;

      // this.mapa = this.lastMap;
      this.finalAno = this.lastMap.ano;
      this.finalMes = this.lastMap.mes + 1;

      if (this.finalMes == 13){
        this.finalMes = 1;
        this.finalAno++;
      }
      
      const routeParams: Object = this.route.snapshot.queryParams;
      
      if (routeParams.hasOwnProperty('mes') && routeParams.hasOwnProperty('ano')) {
        this.routePriority = true;
        this.mes = Number(routeParams['mes']);
        this.ano = Number(routeParams['ano']);
        this.mapaFacade.pickMapa(routeParams['mes'], routeParams['ano']);
      } else 
        this.mapaFacade.pickMapa(this.mes,this.ano);
      
      this.lastMapSubscription.unsubscribe()
    });
    
    this.subscriptions.add(
      this.mapaFacade.currentMapa$.subscribe(mapa => {
        if (!mapa.nome) return;

        if (mapa.mes == this.mes && mapa.ano == this.ano)
          this.mapa = mapa;

        if (!this.routePriority)
          this.syncRoute();
        else 
          this.routePriority = false;

        clearInterval(this.myTimer);

        this.myTimer = setInterval(()=>{
          if(this.mapa.nome && !this.stop && !this.loading){
              
            this.updateTime();
        
            if (this.mapa.mes != this.mes || this.mapa.ano != this.ano) {
              this.mapaFacade.pickMapa(this.mes,this.ano);
              // clearInterval(this.myTimer)
            }
          }
        },4000);
      })
    );
  }
  
  ngOnInit() {
    
  }
  
  pause() {
    this.stop = !this.stop;
  }
  
  rewind() {
    clearInterval(this.myTimer);
    this.interval = -1;
    this.updateTime();
    this.mapaFacade.pickMapa(this.mes,this.ano);
  }
  
  forward() {
    clearInterval(this.myTimer);
    this.interval = 1;
    this.updateTime();
    this.mapaFacade.pickMapa(this.mes,this.ano);
  }

  private syncRoute() 
  {
    this.router.navigate([], {
      queryParams:{
        mes: this.mapa.mes,
        ano: this.mapa.ano
      },
      replaceUrl: true
    });
  }

  updateTime() {
    this.mes = this.mes + this.interval;

    if (this.mes == 0) {
      this.mes = 12;
      this.ano--;

    } else if (this.mes == 13) {
      this.mes = 1;
      this.ano++;
    }
    
    if (this.mes == 6 && this.ano == 2014){
      this.mes = this.lastMap.mes;
      this.ano = this.lastMap.ano;

    } else if (this.mes == this.finalMes && this.ano == this.finalAno) {
      this.mes = 7;
      this.ano = 2014;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    clearInterval(this.myTimer);
  }
}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { MapaMudancaActions, MapaMudancaActionTypes } from './mapa-mudanca.actions';
import { MapaMudanca } from './mapa-mudanca.model';

/**
 * Interface to the part of the Store containing MapaMudancaState
 * and other information related to MapaMudancaData.
 */
export interface MapaMudancaState extends EntityState<MapaMudanca> {
  selectedId: string;
  loading: boolean;
  loaded_at: Date;
}

export const adapter: EntityAdapter<MapaMudanca> = createEntityAdapter<MapaMudanca>({
  selectId: (mapaMudanca: MapaMudanca) => mapaMudanca.id,
});

export const initialState: MapaMudancaState = adapter.getInitialState({
  selectedId: null,
  loading: false,
  loaded_at: null
});

export function mapaMudancaReducer(state = initialState, action: MapaMudancaActions): MapaMudancaState {

  switch (action.type) {
    case MapaMudancaActionTypes.MapaMudancaSelected: {
      return Object.assign({}, state, { selectedId: action.payload, loading: false });
    }

    case MapaMudancaActionTypes.LoadMapaMudancas: {
      return Object.assign({}, state, { loading: true });
    }

    case MapaMudancaActionTypes.MapaMudancasLoaded: {
      return adapter.addAll(action.payload, Object.assign({}, state, { loading: false, loaded_at: new Date() }));
    }
    
    default:
      return state;
  }
}

export const getSelectedMapaMudancaId = (state: MapaMudancaState) => state.selectedId;
export const getLoadingMapaMudanca = (state: MapaMudancaState) => state.loading;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of mapa-mudanca ids
export const selectMapaMudancaIds = selectIds;

// select the dictionary of mapa-mudanca entities
export const selectMapaMudancaEntities = selectEntities;

// select the array of mapa-mudanca
export const selectAllMapaMudancas = selectAll;

// select the total mapa-mudanca count
export const selectMapaMudancaTotal = selectTotal;

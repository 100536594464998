<mat-card >
  <mat-card-header>
    <mat-card-title>Galeria de Fotos</mat-card-title>

  </mat-card-header>

  <mat-divider></mat-divider>

  <mat-card-content>

    <mat-tab-group style="min-height: 390px;">

      <mat-tab label="IMPACTOS DE SECA">

        <!-- Gallery -->
        <div class="my-gallery">
          <ng-container *ngFor="let foto of fotos; let i = index">
            <ng-container *ngIf="foto.type === '1'">
              <figure>
                <a [href]="foto.url" [attr.data-size]="foto.size" (click)="openGallery(i, $event)">
                  <img [src]="foto.url" [alt]="foto.legenda" class="small-image">
                </a>
                <!-- <figcaption>
                  <p>{{ foto.legenda }}</p>
                </figcaption> -->
              </figure>
            </ng-container>
          </ng-container>
        </div>

      </mat-tab>

      <mat-tab label="EQUIPE MONITOR">

        <!-- Gallery -->
        <div class="my-gallery">
          <ng-container *ngFor="let foto of fotos; let i = index">
            <ng-container *ngIf="foto.type === '2'">
              <figure>
                <a [href]="foto.url" [attr.data-size]="foto.size" (click)="openGallery(i, $event)">
                  <img [src]="foto.url" [alt]="foto.legenda" class="small-image">
                </a>
                <!-- <figcaption>
                  <p>{{ foto.legenda }}</p>
                </figcaption> -->
              </figure>
            </ng-container>
          </ng-container>
        </div>
      </mat-tab>

    </mat-tab-group>



<!-- Root element of PhotoSwipe -->
<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="pswp__bg"></div>
  <div class="pswp__scroll-wrap">
    <div class="pswp__container">
      <div class="pswp__item"></div>
      <div class="pswp__item"></div>
      <div class="pswp__item"></div>
    </div>
    <div class="pswp__ui pswp__ui--hidden">
      <div class="pswp__top-bar">
        <div class="pswp__counter"></div>
        <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>
        <!-- <button class="pswp__button pswp__button--share" title="Share"></button> -->
        <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>
        <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>
        <button class="pswp__button pswp__button--play" title="Play slideshow"></button>
        <div class="pswp__preloader">
          <div class="pswp__preloader__icn">
            <div class="pswp__preloader__cut">
              <div class="pswp__preloader__donut"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
        <div class="pswp__share-tooltip"></div>
      </div>
      <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)"></button>
      <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)"></button>
      <div class="pswp__caption">
        <div class="pswp__caption__center" style="font-size: large; max-width: 1000px; text-align: center;"> </div>
      </div>
    </div>
  </div>
</div>


  </mat-card-content>
</mat-card>



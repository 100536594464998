import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { NotificationsService } from '../../notifications/notifications.service';
import { Mapa } from './mapa.model';

@Injectable({
  providedIn: 'root'
})
export class MapaService {

  model = 'rest/cms-msne/mapa-monitor';

  constructor(
    private http: HttpClient,
    private ns: NotificationsService
  ) { }

  getUrl() {
    return `${environment.apiEndpoint}${this.model}`;
  }

  getUrlForId(id) {
    return `${this.getUrl()}/${id}`;
  }

  all() {
    return this.http.get<Mapa[]>(this.getUrl() + '?orderBy=id');
  }

  pick(mes: number, ano: number) {
    // return this.http.get<Mapa>(this.getUrl() + '?mes=' + mes + '&ano=' + ano + '&with=area,descricao,relatorio,shape&limit=1&orderBy=id,desc');
    return this.http.get<Mapa>(this.getUrl() + '?mes=' + mes + '&ano=' + ano + '&final=true&response_format=apil5&use_fk=true&include_all_obj_fk=true&limit=1&orderBy=id,desc');
  }

  loadLast() {
    // return this.http.get<Mapa[]>(this.getUrl() + '?with=area,descricao,relatorio,shape&limit=1&orderBy=id,desc');
    return this.http.get<Mapa[]>(this.getUrl() + '?final=true&response_format=apil5&use_fk=true&include_all_obj_fk=true&limit=1&orderBy=id,desc');
  }
}

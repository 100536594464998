import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BannerModalComponent } from '../../layout/components/banner-modal/banner-modal.component';

@Component({
  selector: 'app-teste',
  templateUrl: './teste.component.html',
  styleUrls: ['./teste.component.css']
})
export class TesteComponent implements OnInit {

  constructor(private dialog:MatDialog) { }

  ngOnInit() {
    this.dialog.open(BannerModalComponent, {
      maxWidth: '98vw',
      data: { } 
    });
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { 
  UfFacade
} from '@funceme/core-monitor';
import { ConfigFacade } from 'libs/core-monitor/src/lib/state/config/config.facade';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public loading: boolean = false;
 

  @ViewChild('drawer', {static: true}) panelOptions:MatDrawer; 

  constructor(
    private ufFacade: UfFacade,
    private configFacade: ConfigFacade
  ) {
    this.configFacade.large();
  }

  ngOnInit() 
  {
    this.ufFacade.loadUfs();
  }
}
import { Component, OnInit, ChangeDetectorRef  } from '@angular/core';
import { MapaFacade, Mapa } from '@funceme/core-monitor';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'app-dados-sig',
  templateUrl: './dados-sig.component.html',
  styleUrls: ['./dados-sig.component.css']
})
export class DadosSIGComponent implements OnInit {

  displayedColumns: string[] = ['data', 'titulo', 'mapa','relatorio', 'shapefiles'];

  public filesEndpoint = environment.filesEndpoint;

  private dataMapaMaisRecente: Date = null;
  private today = new Date();
  public selectedYear;
  public allPeriodos = [];
  public lastMap: Mapa;
  public tableData: {
    mes:number,
    ano:number,
    data:string,
    titulo:string,
    mapa:string,
    relatorio:string,
    shape:string
  }[] = [];

  public loading: boolean = false;

  public routePriority: boolean = false;

  public lastMapSubscription;
  public subscriptions;

  constructor(
    private mapaFacade: MapaFacade,
    private router: Router,
    private route: ActivatedRoute
  ) {
    // this.subscriptions = this.mapaFacade.loading$.subscribe(value => this.loading = value);

    this.mapaFacade.selectMapa(null);
    this.mapaFacade.loadMapaRecente();

    this.lastMapSubscription = this.mapaFacade.currentMapa$.subscribe(value => {
      if (!value.ano) return;
      this.lastMap = value;
      this.setAllPeriodos();

      this.selectedYear = this.allPeriodos[0];

      const routeParams: Object = this.route.snapshot.queryParams;

      if (routeParams.hasOwnProperty('ano')) {
        this.routePriority = true;
        this.selectedYear = Number(routeParams['ano']);
      }

      this.setTableData();

      this.tableData = [...this.tableData];

      this.subscriptions = this.mapaFacade.currentMapa$.subscribe(mapa => {
        if (!mapa.nome) return;
  
        if (mapa.ano == this.selectedYear && !this.tableData.find(value => value.mes == mapa.mes)){
          this.tableData.push({
            mes:mapa.mes,
            ano:mapa.ano,
            data:mapa.mes+'/'+mapa.ano,
            titulo:mapa.nome,
            mapa: (mapa.path) ? mapa.path : '-',
            relatorio: (mapa.relatorio[0]) ? mapa.relatorio[0].path : '-',
            shape: (mapa.shape[0]) ? mapa.shape[0].path : '-'
          })
        }
        this.tableData = [...this.tableData];
        if (mapa.mes == 1 || (mapa.ano == 2014 && mapa.mes == 7)){
          this.loading = false;
          if (!this.routePriority)
            this.syncRoute();
          else 
            this.routePriority = false;
        }
      });
    })
  }

  ngOnInit() {
    // console.log(this.tableData);
    
  }

  changedYear(event){
    this.selectedYear = event.value;
    this.tableData.length = 0;
    this.setTableData()
  }

  private syncRoute() 
  {
    this.router.navigate([], {
      queryParams:{
        ano: this.selectedYear
      },
      replaceUrl: true
    });
  }

  setAllPeriodos() {
    let year = this.lastMap.ano;
    // let month = this.lastMap.mes;
    let c = 100;
    while (year != 2013){
      this.allPeriodos.push(year);
      year--;
    }

    this.lastMapSubscription.unsubscribe()
  }

  setTableData() {
    this.loading = true;
    this.mapaFacade.pickMapa(null,null);

    let mes;
    if( this.selectedYear == this.today.getFullYear())
      mes = this.lastMap.mes;
    else
      mes = 12;
    
    while (mes != 0) {
      if (this.selectedYear == 2014 && mes == 6) return;
      this.mapaFacade.pickMapa(mes,this.selectedYear);
      // this.mapaFacade.pickMapa(null,null);
      mes--;
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions)
      this.subscriptions.unsubscribe();
  }
}

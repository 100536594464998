// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  filesEndpoint: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/',
  //apiEndpoint: 'https://apimsbr.funceme.br/'
  apiEndpoint: 'https://apimsbr.ana.gov.br/'
};

export const authConfig = {
  client_id: 50,
  client_secret: 'ueuWlHUrDb6rPz0mMHlai3BSDZbQru4XbUYoaIAM',
  guest_username: 'monitor_r@bot.br',
  guest_password: '#monitor_R#',
  route_default: 'mapa'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
export const version = "5.0.22";

<mat-card style="padding: 0px;">
  <mat-card-header>
    <mat-card-title>Dados SIG</mat-card-title>
  </mat-card-header>

  <mat-divider></mat-divider>
  
  <mat-progress-bar mode="indeterminate" [class.show]="loading" [class.hide]="!loading"></mat-progress-bar>

  <mat-card-content>
    <div class="container">
      <div class="selector">
          <mat-form-field>
            <mat-label>Selecione o ano</mat-label>
            <mat-select [value]="selectedYear" (selectionChange)="changedYear($event)">
              <mat-option *ngFor="let periodo of allPeriodos" [value]="periodo">
                {{ periodo }}
              </mat-option>
            </mat-select>
          </mat-form-field>
      </div>

      <div class="table-hover">
        <mat-table [dataSource]="tableData" class="mat-elevation-z8">
          <ng-container matColumnDef="data">
              <mat-header-cell *matHeaderCellDef> Data </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.data}} </mat-cell>
          </ng-container>
          
          <ng-container matColumnDef="titulo">
              <mat-header-cell *matHeaderCellDef> Título </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <a [routerLink]="['/mapa']" [queryParams]="{mes:element.mes, ano:element.ano}">{{element.titulo}} </a>
              </mat-cell>
          </ng-container>
          
          <ng-container matColumnDef="mapa">
              <mat-header-cell *matHeaderCellDef> Mapa </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <a *ngIf="element.mapa != '-'" href="{{filesEndpoint}}{{element.mapa}}" target="_blank">PNG</a>
                <div *ngIf="element.mapa == '-'"> - </div>
              </mat-cell>
          </ng-container>
          
          <ng-container matColumnDef="relatorio">
              <mat-header-cell *matHeaderCellDef> Relatório </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <a *ngIf="element.relatorio != '-'" href="{{filesEndpoint}}{{element.relatorio}}" target="_blank">PDF</a>
                <div *ngIf="element.relatorio == '-'"> - </div>
              </mat-cell>
          </ng-container>

          <ng-container matColumnDef="shapefiles">
              <mat-header-cell *matHeaderCellDef> Shapefiles </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <a *ngIf="element.shape != '-'" href="{{filesEndpoint}}{{element.shape}}" target="_blank">ZIP</a>
                <div *ngIf="element.shape == '-'"> - </div>
              </mat-cell>
          </ng-container>
          
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
    </div>
  </mat-card-content>
</mat-card>

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router, Params } from '@angular/router';

@Component({
  selector: 'funceme-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  queryParams: Params;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) { 

    this.route.queryParams.subscribe( params => {
        this.queryParams = params;
    });
  }

  ngOnInit() {
    this.authService.login().subscribe(value => {
      localStorage.setItem('access_token', 'Bearer ' + value.access_token);
          
      this.router.navigate([localStorage.getItem('redirect_url')], {
        queryParams: JSON.parse(localStorage.getItem('redirect_params'))
      })
    });
  }
}

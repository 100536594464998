<mat-card style="padding: 0px;">
    <mat-card-header>
      
        
    </mat-card-header>

    <mat-divider></mat-divider>
    
    <mat-progress-bar mode="indeterminate" [class.show]="loading" [class.hide]="!loading"></mat-progress-bar>

    <mat-card-content>
        
    </mat-card-content>
</mat-card>


import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment, authConfig } from '@env/environment';
import { Login } from './login.model';

@Injectable({
    providedIn: 'root'
})

export class AuthService 
{
    private callAuthService = new Subject<any>();
    callAuthService$ = this.callAuthService.asObservable();

    constructor(public jwtHelper: JwtHelperService, protected http: HttpClient, private router: Router) 
    { 
        
    }

    getUrl() {
        return `${environment.apiEndpoint}oauth/token`;
    }
    

    public isTokenExpired(): boolean
    {
        return this.jwtHelper.isTokenExpired(localStorage.getItem('access_token'));
    }

    public isAuthenticated(): boolean 
    {
        const token = localStorage.getItem('access_token');

        if (token == 'null' || token == null)
            return false;

        return !this.jwtHelper.isTokenExpired(token);
    }

    public login() 
    {
        return this.http.post<Login>(this.getUrl(), {
            grant_type :    "password",
            client_id:      authConfig.client_id,
            client_secret:  authConfig.client_secret,
            username:       authConfig.guest_username,
            password:       authConfig.guest_password,
            scope:          "*"
        });        
    }

    public logout()
    {
        localStorage.setItem('access_token', null);
        localStorage.setItem('user_name', null);
        localStorage.setItem('user_id', null);  
    }
}
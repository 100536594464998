<mat-card style="padding: 0px;">
  <mat-card-header>
    <mat-card-title>Animações dos mapas</mat-card-title>
      
  </mat-card-header>

  <mat-divider></mat-divider>
  
  <mat-progress-bar mode="indeterminate" [class.show]="loading" [class.hide]="!loading"></mat-progress-bar>

  <mat-card-content>
      <div class="container">
        <div class="controllers">
          <div class="btns">
            <button mat-button *ngIf="!stop" class="btn" (click)="pause()" style="color: #E85D01">
              <mat-icon>pause</mat-icon>
            </button>

            <button mat-button *ngIf="stop" class="btn" (click)="pause()" style="color: #E85D01">
              <mat-icon>play_arrow</mat-icon>
            </button>
        
            <button mat-button class="btn" (click)="rewind()" style="color: #E85D01">
              <mat-icon>fast_rewind</mat-icon>
            </button>
        
            <button mat-button class="btn" (click)="forward()" style="color: #E85D01">
              <mat-icon>fast_forward</mat-icon>
            </button>
          </div>
        </div>
        <div class="map">
          <img *ngIf="mapa.path" src="{{filesEndpoint}}{{ mapa.path }}" class="img-responsive"/>
        </div>
      </div>
      
  </mat-card-content>
</mat-card>

import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewMode } from 'libs/core-monitor/src/lib/state/config/config.model';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from 'libs/core-monitor/src/lib/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { BannerModalComponent } from '../../layout/components/banner-modal/banner-modal.component';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css']
})
export class RootComponent implements OnInit {

  public viewMode: ViewMode = ViewMode.LARGE;

  @ViewChild('sidenav', {static: true}) sidenav: MatSidenav;

  constructor(private router: Router, private auth: AuthService, private dialog:MatDialog) 
  {
    const navEndEvents$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );

    navEndEvents$.subscribe((event: NavigationEnd) => {
      gtag('config', 'UA-7298747-11',{
          'page_path': event.urlAfterRedirects
      });
    })
  }

  ngOnInit() 
  {
    // this.dialog.open(BannerModalComponent, {
    //   maxWidth: '98vw',
    //   data: { } 
    // });
  }

  close() 
  {
    this.sidenav.close();
  }

  link(url:string) {
    this.close();
    this.router.navigate([url]);
  }
}
<footer class="container footer layout-content">
    <div  class="layout-content">
      <div id="footer">
        <div id="footer-content">
            <div class="content">
                <div class="content-colluns">
                    <div class="colluns servicos">
                        <h2 href="#servicos">Serviços</h2>
                        <ul>
                            <li><a routerLink="/alteracoes-mensais">Alterações mensais</a></li>
                            <li><a routerLink="/animacoes">Animações dos mapas</a></li>
                            <li><a routerLink="/comparacoes-mapas">Comparações de mapas</a></li>
                            <li><a routerLink="/dados-tabulares">Dados tabulares</a></li>
                            <li><a routerLink="/dados-sig">Dados SIG</a></li>
                            <li><a routerLink="/mapa">Mapa mais recente</a></li>
                        </ul>
                    </div>

                    <div class="colluns sobre">
                        <h2 href="#servicos">Sobre o monitor</h2>
                        <ul>
                            <li><a routerLink="/o-monitor-de-secas">O Monitor de Secas</a></li>
                            <li><a routerLink="/a-origem-do-monitor">A origem do Monitor de Secas</a></li>
                            <li><a routerLink="/aplicativos">Aplicativos</a></li>
                            <li><a routerLink="/calendario-de-mapas">Calendário de mapas</a></li>
                            <li><a routerLink="/expansao-do-monitor">Expansão do Monitor</a></li>
                            <li><a routerLink="/instituicoes-parceiras">Instituições parceiras</a></li>
                            <li><a routerLink="/publicacoes">Publicações</a></li>
                            <li><a routerLink="/tabela-de-classificacao">Tabela de Classificação de Severidade da Seca</a></li>
                        </ul>
                    </div>

                    <div class="colluns outros">
                        <h2 href="#servicos">Outros</h2>
                        <ul>
                          <li><a target="_blank" href="http://funceme.br/app-zend-monitordesecas/auth">Acesso Restrito</a></li>
                          <li><a routerLink="/contato">Contatos</a></li>
                          <li><a routerLink="/perguntas-frequentes">Perguntas Frequentes</a></li>
                        </ul>
                    </div>
                    <div class="clear-both"></div>

                </div>
                <div class="description" style="display:none">
                    <!--<p>Copyright © 2015 - <a target="_blank" href="http://www.funceme.br/">FUNCEME</a>. Todos os direitos reservados.</p>-->
                </div>
            </div>
          </div>
        </div>
        <div class="description">
          <p align="center">
            Desenvolvido por Monitor de Secas - 2023 - {{ ver }}
          </p>
          <div align="center" style="padding-bottom: 25px;">
          <a class="app_link" style="margin: 0 5px;" href="https://play.google.com/store/apps/details?id=br.funceme.monitordesecas" target="_blank">
            <img width="100" src="/assets/img/google-play.png" alt="Google Play">
          </a>

          <a class="app_link" style="margin: 0 5px;"  href="https://itunes.apple.com/br/app/monitor-de-secas/id1262006780" target="_blank">
            <img width="100" src="/assets/img/app-store.png" alt="App Store">
          </a>
        </div>
        </div>
    </div>
  </footer>


<mat-card style="padding: 0px;">
    <mat-card-header>
        <mat-card-title>Contato</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-progress-bar mode="indeterminate" [class.show]="loading" [class.hide]="!loading"></mat-progress-bar>

    <mat-card-content>
        <mat-card id="mat-card-form">
            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="form-horizontal">
                <mat-form-field>
                    <input matInput formControlName="nome" [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" placeholder="Nome" size="120" type="text" />
                </mat-form-field>
                <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                    <div *ngIf="f.nome.errors.required">Nome é requerido</div>
                    <div *ngIf="f.nome.errors.minlength">Necessário pelo menos 3 caracteres</div>
                </div>
                <mat-form-field>
                    <input matInput formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="E-mail" size="120" type="text" />
                </mat-form-field>
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">E-mail é requerido</div>
                    <div *ngIf="f.email.errors.email">E-mail deve ser um e-mail valido</div>
                </div>
                <mat-form-field>
                    <input matInput formControlName="telefone" [ngClass]="{ 'is-invalid': submitted && f.telefone.errors }" placeholder="Telefone" size="15" type="text" />
                  </mat-form-field>
                  <div *ngIf="submitted && f.telefone.errors" class="invalid-feedback">
                      <div *ngIf="f.telefone.errors.required">O telefone  é requerida</div>
                  </div>
                <mat-form-field>
                    <textarea matInput formControlName="mensagem" [ngClass]="{ 'is-invalid': submitted && f.mensagem.errors }" placeholder="Mensagem"></textarea>
                </mat-form-field>
                <div *ngIf="submitted && f.mensagem.errors" class="invalid-feedback">
                    <div *ngIf="f.mensagem.errors.required">A mensagem é requerida</div>
                </div>
                <div class="margin-bottom-recaptcha">
                    <re-captcha (resolved)="resolved($event)" siteKey="6LcuyEYUAAAAAGOD9AvUXLfZM5i7dr-3dW9UQjhE">
                    </re-captcha>
                </div>
                <button mat-raised-button color="primary">Enviar</button>
                <button mat-raised-button color="basic" (click)="onReset()">Limpar</button>
            </form>
        </mat-card>
    </mat-card-content>
</mat-card>

<div class="container margin-bottom">
  <h2>Downloads</h2>
  <h4 class="text-justify">Baixe aqui gratuitamente o QGIS, um Sistema de Informação Geográfica (SIG) de código aberto
    para que você possa ter acesso aos arquivos deste projeto.</h4>
  <ul>
    <li>
      Windows
      <ul>
        <li>
          <a href="http://download.osgeo.org/qgis/win32/QGIS-OSGeo4W-2.18.24-1-Setup-x86.exe">Versão 2.18 - Win32</a>
        </li>
        <li>
          <a href="http://download.osgeo.org/qgis/win64/QGIS-OSGeo4W-2.18.24-1-Setup-x86_64.exe">Versão 2.18 -
            Win64</a>
        </li>
      </ul>
    </li>
    <li>
      Mac
      <ul>
        <li>
          <a href="http://download.osgeo.org/qgis/mac/qgis-1.0.0pre2.dmg.gz">Versão 1.0</a>
        </li>
      </ul>
    </li>
    <li>
      Linux
      <ul>
        <li>
          CentOS 5x
          <ul>
            <li>
              <a href="http://download.osgeo.org/qgis/linux/CentOS_5x/qgis-0.11.0/" target="_blank">Versão 0.11.0</a>
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          Debian e Ubuntu
          <ul>
            <li>
              <a href="https://qgis.org/debian-ltr/" target="_blank">Versão LTR</a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</div>
import { Component, OnInit } from '@angular/core';
import * as PhotoSwipe from 'photoswipe';
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.css']
})
export class GaleriaComponent {
  fotos: { url: string, type: string, legenda: string, size: string }[] = [
    // Adicione mais objetos com URL de imagem e legenda conforme necessário
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_01.jpeg', type: '1', legenda: 'Foto 1 – Município de Corumbá no estado de Mato Grosso do Sul (MS), Junho / 2024', size: '1600x1200' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_02.jpeg', type: '1', legenda: 'Foto 2 – Município de Corumbá no estado de Mato Grosso do Sul (MS), Junho / 2024', size: '1600x1200' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_03.jpeg', type: '2', legenda: 'Foto 3 – Equipe de Validadores do Monitor de Secas do estado do Rio de Janeiro (RJ), 2024', size: '960x1280' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_04.jpeg', type: '2', legenda: 'Foto 4 – Equipe de Autores do Monitor de Secas no estado de Pernambuco (PE), 2024', size: '1280x960' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_05.jpeg', type: '1', legenda: 'Foto 5 – Orla do município de Manaus no estado do Amazonas (AM), Outubro / 2023', size: '1280x960' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_06.jpeg', type: '1', legenda: 'Foto 6 – Orla do município de Manaus no estado do Amazonas (AM), Outubro / 2023', size: '1280x960' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_07.jpeg', type: '1', legenda: 'Foto 7 – Orla do município de Manaus no estado do Amazonas (AM), Outubro / 2023', size: '1280x960' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_08.jpeg', type: '1', legenda: 'Foto 8 – Município de Eirunepe no estado do Amazonas (AM), Abril / 2024', size: '1280x622' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_09.jpeg', type: '2', legenda: 'Foto 9 – Oficina de Autoria em Brasília (DF), 2017', size: '1024x768' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_10.jpeg', type: '2', legenda: 'Foto 10 – Oficina de Avaliação da região Sudeste / Sul no Rio de Janeiro (RJ), Maio / 2023', size: '960x1280' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_11.jpeg', type: '1', legenda: 'Foto 11 – Município de Tabatinga no estado do Amazonas (AM), Abril / 2024', size: '1600x900' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_12.jpeg', type: '2', legenda: 'Foto 12 – Oficina de Autoria em Brasília (DF), 2018', size: '1024x768' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_13.jpeg', type: '1', legenda: 'Foto 13 – Município de Iramaia no estado da Bahia (BA), Setembro / 2023', size: '4080x3072' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_14.jpeg', type: '2', legenda: 'Foto 14 – Treinamento de Validadores no Corpo de Bombeiros do Espírito Santo (ES), 2018', size: '1280x776' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_15.jpeg', type: '1', legenda: 'Foto 15 – Município de Envira no estado do Amazonas (AM), Julho / 2024', size: '1040x468' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_16.jpeg', type: '1', legenda: 'Foto 16 – Estado do Rio Grande do Sul (RS), Novembro / 2022', size: '655x442' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_17.jpeg', type: '2', legenda: 'Foto 17 – Treinamento do Monitor de Secas do Nordeste em Fortaleza no estado do Ceará (CE), Agosto / 2014', size: '1280x868' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_18.jpeg', type: '1', legenda: 'Foto 18 – Riacho Cagado na Bacia Itapicuru no estado da Bahia (BA), Outubro / 2019', size: '5152x3864' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_19.jpeg', type: '1', legenda: 'Foto 19 – Rio Quinjingue na Bacia Itapicuru no estado da Bahia (BA), Outubro / 2023', size: '4000x2250' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_20.jpeg', type: '2', legenda: 'Foto 20 – Treinamento do Monitor de Secas do Nordeste em Fortaleza, 2016', size: '3264x1836' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_21.jpeg', type: '1', legenda: 'Foto 21 - Município de Corumbá no estado de Mato Grosso do Sul (MS), Maio / 2024', size: '1600x1200' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_22.jpeg', type: '1', legenda: 'Foto 22 – Rio Macururé no estado da Bahia (BA), Novembro / 2023', size: '4608x2888' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_23.jpeg', type: '2', legenda: 'Foto 23 – Treinamento de Observadores no estado de Pernambuco (PE), 2017', size: '4160x2336' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_24.jpeg', type: '2', legenda: 'Foto 24 – Treinamento de Validadores no estado do Amapá (AP), Novembro / 2023', size: '1280x960' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_25.jpeg', type: '2', legenda: 'Foto 25 – Equipe de Validadores do Monitor de Secas no estado do Rio Grande do Sul (RS), 2024', size: '800x1600' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_26.jpeg', type: '2', legenda: 'Foto 26 – Treinamento de Validadores no estado de Rondônia (RO), Julho / 2022', size: '1600x1200' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_27.jpeg', type: '2', legenda: 'Foto 27 – Treinamento de Validadores no estado do Amazonas (AM), Novembro / 2022', size: '1280x960' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_28.jpeg', type: '1', legenda: 'Foto 28 – Estação Meteorológica do município de Várzea Nova no estado da Bahia (BA), Janeiro / 2018', size: '2988x5312' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_29.jpeg', type: '2', legenda: 'Foto 29 – Encontro do Monitor de Secas em Salvador na Bahia (BA), Novembro / 2014', size: '1170x880' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_30.jpeg', type: '1', legenda: 'Foto 30 – Município de Envira no estado do Amazonas (AM), Julho / 2024', size: '1600x720' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_31.jpeg', type: '2', legenda: 'Foto 31 – Equipe de Validadores em visita a campo no município de Uiramutã, no estado de Roraíma (RR), Novembro / 2023', size: '1280x960' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_32.jpeg', type: '1', legenda: 'Foto 32 – Município de Antonina do Norte no estado do Ceará (CE), 2012', size: '1801x1196' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_33.jpeg', type: '1', legenda: 'Foto 33 – Lago dos Americanos no município de Boa Vista no estado de Roraima (RR), Janeiro / 2024', size: '965x579' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_34.jpeg', type: '1', legenda: 'Foto 34 – Município de Boa Vista no estado de Roraima (RR), Fevereiro / 2024', size: '1068x600' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_35.jpeg', type: '1', legenda: 'Foto 35 – Município de Boa Vista no estado de Roraima (RR), Fevereiro / 2024', size: '1036x630' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_36.jpeg', type: '1', legenda: 'Foto 36 – Rio Espinharas em Serra Negra do Norte no estado de Rio Grande do Norte (RN), 2010', size: '1196x1801' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_37.jpeg', type: '1', legenda: 'Foto 37 – Vertedouro do Açude Orós no estado do Ceará (CE), 2010', size: '1801x1196' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_38.jpeg', type: '1', legenda: 'Foto 38 – Açude na rodovia CE-284 entre os municípios de Antonina do Norte e Jucás no Ceará (CE), 2010', size: '1801x1196' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_39.jpeg', type: '1', legenda: 'Foto 39 – Açude na rodovia CE-284 entre os municípios de Campos Sales e Antonina do Norte no estado do Ceará (CE), 2010', size: '1196x1801' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_40.jpeg', type: '1', legenda: 'Foto 40 – Reservatório de água na BR-135 em Santo Antônio dos Lopes no Maranhão (MA), 2010', size: '1801x1196' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_41.jpeg', type: '1', legenda: 'Foto 41 – Riacho da Saudade em Gilbués no estado do Piauí (PI), Outubro / 2023', size: '1179x2023' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_42.jpeg', type: '1', legenda: 'Foto 42 – Lago Cajari no município de Viana no estado do Maranhão (MA), 2010', size: '1801x1196' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_43.jpeg', type: '1', legenda: 'Foto 43 – Chafariz no município de Mossoró no estado de Rio Grande do Norte (RN), 2006', size: '3413x5120' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_44.jpeg', type: '1', legenda: 'Foto 44 – Rio entre as cidades de Caxias e Peritoró no estado do Maranhão (MA), 2010', size: '1196x1801' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_45.jpeg', type: '1', legenda: 'Foto 45 – Paisagem do Cariri no estado da Paraíba (PB), 2006', size: '5120x3413' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_46.jpeg', type: '1', legenda: 'Foto 46 – Foz do Paraíba do Sul no município de São Joao da Barra no estado do Rio de Janeiro (RJ), 2014', size: '4000x3000' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_47.jpeg', type: '2', legenda: 'Foto 47 – Treinamento de Validadores no estado do Pará (PA), Fevereiro / 2023', size: '906x637' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_48.jpeg', type: '1', legenda: 'Foto 48 – Açude Soledade no município de Soledade no estado da Paraíba (PB), Outubro / 2019', size: '1280x960' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_49.jpeg', type: '2', legenda: 'Foto 49 – Validadores da Paraíba (PB) apresentando trabalho sobre o comportamento da chuva no seu estado durante a seca de 2012 a 2017', size: '1200x1600' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_50.jpeg', type: '1', legenda: 'Foto 50 – Estado do Rio Grande do Sul (RS), Novembro / 2022', size: '824x463' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_51.jpeg', type: '1', legenda: 'Foto 51 – Rio Gravataí no estado do Rio Grande do Sul (RS), 2022', size: '1024x682' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_52.jpeg', type: '1', legenda: 'Foto 52 – Município de Lajes em Rio Grande do Norte (RN), 2017', size: '1600x1200' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_53.jpeg', type: '1', legenda: 'Foto 53 – Município de Lajes em Rio Grande do Norte (RN), 2017', size: '1600x1200' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_54.jpeg', type: '1', legenda: 'Foto 54 – Palma Forrageira no estado de Rio Grande do Norte (RN), 2017', size: '1600x1200' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_55.jpeg', type: '1', legenda: 'Foto 55 – Afluente do Rio Ceará-Mirim no estado de Rio Grande do Norte (RN), Agosto / 2018', size: '3264x2448' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_56.jpeg', type: '1', legenda: 'Foto 56 – Jusante da Usina Hidreletrica Ferreira Gomes no estado do Amapá (AP), Outubro / 2023', size: '960x1280' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_57.jpeg', type: '1', legenda: 'Foto 57 – Rio Tartarugalzinho no estado do Amapá (AP), Outubro / 2023', size: '960x1280' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_58.jpeg', type: '1', legenda: 'Foto 58 – Represa de Piraquara no estado do Paraná (PR), Setembro / 2021', size: '1280x720' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_59.jpeg', type: '1', legenda: 'Foto 59 – Barragem do Rio Iraí no estado do Paraná (PR), Abril / 2020', size: '3000x2000' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_60.jpeg', type: '1', legenda: 'Foto 60 – Rio Paranapanema na divisa dos estados de São Paulo (SP) e Paraná (PR), Maio / 2021', size: '900x506' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_61.jpeg', type: '1', legenda: 'Foto 61 – Município de Arraias no estado de Tocantins (TO), 2023', size: '2560x1706' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_62.jpeg', type: '1', legenda: 'Foto 62 – Rio Dueré no município de Dueré no estado de Tocantins (TO), 2023', size: '709x945' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_63.jpeg', type: '1', legenda: 'Foto 63 – Rio Cayuguava no estado do Paraná (PR), Novembro / 2020', size: '1600x1066' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_64.jpeg', type: '1', legenda: 'Foto 64 – Rio Chapecó no estado de Santa Catarina (SC), 2019', size: '1600x1066' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_65.jpeg', type: '1', legenda: 'Foto 65 – Rio Uruguai, Alto Bela Vista, Santa Catarina (SC), 2019', size: '1600x1598' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_66.jpeg', type: '1', legenda: 'Foto 66 – Barragem Lageado São José no município de Chapecó, 2019', size: '799x600' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_67.jpeg', type: '1', legenda: 'Foto 67 – Rio Madeira no município de Porto Velho no estado de Rondônia (RO), 2024', size: '1026x1351' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_68.jpeg', type: '1', legenda: 'Foto 68 – Rio Madeira no município de Porto Velho no estado de Rondônia (RO), 2024', size: '1280x960' },

    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_69.jpeg', type: '1', legenda: 'Foto 69 – Lago na Rede de Desenvolvimento Sustentável do Juma no município Novo Aripuanã no estado do Amazonas (AM), Setembro / 2024', size: '960x1280' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_70.jpeg', type: '1', legenda: 'Foto 70 – Comunidade Santo Antônio do Tiriricatra na Rede de Desenvolvimento Sustentável do Rio Negro no município de Novo Airão no estado do Amazonas (AM), Setembro / 2024', size: '1280x960' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_71.jpeg', type: '1', legenda: 'Foto 71 - Lago do Cipotuba na Rede de Desenvolvimento Sustentável do Juma no município de Novo Aripuanã no estado do Amazonas (AM), Setembro / 2024', size: '960x1280' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_72.jpeg', type: '1', legenda: 'Foto 72 – Comunidade Paraisinho no Rio Madeira no município de Humaitá no estado do Amazonas (AM), Setembro / 2024', size: '806x536' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_73.jpeg', type: '1', legenda: 'Foto 73 – Rio Juruá no município de Eirunepé́ no estado do Amazonas (AM),Setembro / 2024', size: '1280x591' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_74.jpeg', type: '1', legenda: 'Foto 74 – Rio Madeira no município de Novo Aripuanã̃ no estado do Amazonas (AM), Setembro / 2024', size: '1600x1200' },
    { url: 'https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/fotos/Foto_75.jpeg', type: '1', legenda: 'Foto 75 - Rio Madeira no município de Novo Aripuanã no estado do Amazonas (AM), Setembro / 2024', size: '715x403' }
  ];

  openGallery(index: number, event: Event): void {
    event.preventDefault();

    const pswpElement = document.querySelectorAll('.pswp')[0] as HTMLElement;

    const items = this.fotos.map(foto => {
      const [width, height] = foto.size.split('x').map(Number);
      return {
        src: foto.url,
        w: width,
        h: height,
        title: foto.legenda
      };
    });

    const options = {
      index: index,
      bgOpacity: 0.9,
      showHideOpacity: true
    };

    const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
    gallery.init();

    let slideInterval;
    const interval = 3000; // Tempo em milissegundos para cada slide

    const startSlideshow = () => {
      slideInterval = setInterval(() => {
        if (gallery.getCurrentIndex() < gallery.items.length - 1) {
          gallery.next();
        } else {
          gallery.goTo(0); // Voltar à primeira imagem após a última
        }
      }, interval);
    };

    const stopSlideshow = () => {
      clearInterval(slideInterval);
    };

    gallery.listen('destroy', () => {
      stopSlideshow();
    });

    const playButton = document.querySelector('.pswp__button--play');
    playButton?.addEventListener('click', () => {
      if (playButton.classList.contains('pswp__button--pause')) {
        stopSlideshow();
        playButton.classList.remove('pswp__button--pause');
        playButton.classList.add('pswp__button--play');
      } else {
        startSlideshow();
        playButton.classList.remove('pswp__button--play');
        playButton.classList.add('pswp__button--pause');
      }
    });
  }

  private addPlayButton() {
    const pswpElement = document.querySelectorAll('.pswp')[0] as HTMLElement;
    const playButton = document.createElement('button');
    playButton.className = 'pswp__button pswp__button--play';
    playButton.title = 'Play slideshow';
    pswpElement.querySelector('.pswp__top-bar')?.appendChild(playButton);
  }

  constructor() { }

  ngOnInit(): void {
    // this.addPlayButton();
  }

}

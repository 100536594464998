import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-publicacoes',
  templateUrl: './publicacoes.component.html',
  styleUrls: ['./publicacoes.component.css']
})
export class PublicacoesComponent implements OnInit {

  public filesEndpoint = environment.filesEndpoint;
  public subscriptions;


  public publicacoes = [
    {
      nome: "Série Água Brasil",
      url: this.filesEndpoint+"data/publicacoes/Serie_Agua_10_-_Monitor_Secas_do_Nordeste.pdf",
      thumb: this.filesEndpoint+"uploads/img/thumb.png",
    },{
      nome: "Cartilha do Validador 2020",
      url: this.filesEndpoint+"data/publicacoes/Cartilha_do_Validador_(final_2020).pdf",
      thumb: this.filesEndpoint+"uploads/img/thumb-cartilha-validador.png"
    },{
      nome: "Cartilha do Observador 2020",
      url: this.filesEndpoint+"data/publicacoes/Cartilha_do_Observador_FINAL_(2020)_-_sangria_e_marcas_de_corte.pdf",
      thumb: this.filesEndpoint+"uploads/img/thumb-cartilha-observador.png"
    },{
      nome: "Glossário",
      url: this.filesEndpoint+"data/publicacoes/Glossario_v3.pdf",
      thumb: this.filesEndpoint+"uploads/img/thumb-glossario.png"
    },{
      nome: "Secas no Brasil",
      url: this.filesEndpoint+"data/publicacoes/seca_brasil-publicaçãoCGEE.pdf",
      thumb: this.filesEndpoint+"uploads/img/thumb-secas-brasil.jpeg"
    }

  ];

  constructor(
    private http: HttpClient
  ) {
    // this.subscriptions = this.http.get('https://apil5.funceme.br/minio/download/msne?file=uploads/documentos/monitor_publicacoes.txt').subscribe(
    // this.subscriptions = this.http.get('https://api1.funceme.br/minio/download/msne?file=uploads/documentos/monitor_publicacoes.txt').subscribe(

    //     data => {
    //       this.publicacoes = JSON.parse(data['file']);
    //     }
    //   );
  }

  ngOnInit() {
  }

  navigate(url) {
    window.open(url,"_blank");
  }

  // ngOnDestroy(): void {
  //   this.subscriptions.unsubscribe();
  // }

}

<mat-toolbar class="mat-elevation-z4">
  <mat-toolbar-row >

    <button mat-button (click)="link('/mapa')">
      <mat-icon aria-hidden="false" aria-label="Mapas">home</mat-icon> Mapas
    </button>

    <button mat-button [matMenuTriggerFor]="menuAbout">Sobre o Monitor<mat-icon>arrow_drop_down</mat-icon></button>
    <mat-menu #menuAbout="matMenu" yPosition="below">
      <button mat-menu-item routerLink="/o-monitor-de-secas">O Monitor de Secas</button>
      <!-- <button mat-menu-item routerLink="/a-origem-do-monitor">A Origem do Monitor</button> -->
      <button mat-menu-item routerLink="/x-anos">10 anos</button>
      <button mat-menu-item routerLink="/quem-somos">Quem somos</button>
      <!-- <button mat-menu-item routerLink="/aplicativos">Aplicativos</button> -->
      <!-- <button mat-menu-item routerLink="/calendario-de-mapas">Calendários de Mapas</button> -->
      <!-- <button mat-menu-item routerLink="/expansao-do-monitor">Expansão do Monitor</button> -->
      <!-- <button mat-menu-item routerLink="/identidade-visual">Identidade Visual</button> -->
      <!-- <button mat-menu-item routerLink="/instituicoes-parceiras">Instituições Parceiras</button> -->
      <button mat-menu-item routerLink="/publicacoes">Publicações</button>
      <button mat-menu-item routerLink="/tabela-de-classificacao">Tabela de Severidade de Seca</button>
    </mat-menu>

    <button mat-button (click)="link('/calendario-de-mapas')">
      Calendário
    </button>

    <button mat-button [matMenuTriggerFor]="menuDataAndMaps">Dados e Mapas<mat-icon>arrow_drop_down</mat-icon></button>
    <mat-menu #menuDataAndMaps="matMenu" yPosition="below">
      <button mat-menu-item routerLink="/mapa">Mapa mais recente</button>
      <button mat-menu-item routerLink="/alteracoes-mensais">Alterações Mensais</button>
      <button mat-menu-item (click)="link('animacoes')">Animações dos Mapas</button>
      <button mat-menu-item (click)="link('/comparacoes-mapas')">Comparações de Mapas</button>
      <button mat-menu-item (click)="link('dados-sig')">Dados SIG</button>
      <button mat-menu-item (click)="link('/dados-tabulares')">Dados Tabulares</button>
    </mat-menu>

    <button mat-button routerLink="/perguntas-frequentes">
      Perguntas frequentes
    </button>
    <button mat-button (click)="link('/contato')">
      Contato
    </button>

    <span class="fill-space"></span>

    <!-- <button mat-button (click)="admin()">
      <mat-icon aria-hidden="false" aria-label="Home">lock</mat-icon> Acesso restrito
    </button> -->

  </mat-toolbar-row>
</mat-toolbar>


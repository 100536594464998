<mat-card style="padding: 0px;">
  <mat-card-header>
    <mat-card-title>Monitor de Secas – 10 anos!</mat-card-title>

  </mat-card-header>

  <mat-divider></mat-divider>

  <mat-card-content>
    <app-video-xanos></app-video-xanos>
    <p></p>

    <div class="container text-justify">
    <p>O Monitor de Secas teve início em julho de 2014, inicialmente como Monitor de Secas do Nordeste, durante uma das
      maiores secas já registradas na Região. A crise provocada por esse fenômeno catalisou discussões sobre a
      necessidade de uma mudança de paradigma na gestão de secas, enfatizando ações proativas e políticas de preparação,
      resposta e mitigação de seus impactos.</p>
    <p>Entre 2010 e 2013, a Agência Nacional de Águas (ANA) e o Banco Mundial realizaram estudos e assistências técnicas
      relacionadas às mudanças climáticas e à preparação paras as secas na Região Nordeste. Nesse último ano, o trabalho
      foi uma demanda do Ministério da Integração, motivado pelo evento de seca iniciado em 2012. Construíram-se, assim,
      as primeiras ações para avançar na elaboração de uma Política Nacional de Secas.</p>
    <p>Um dos eixos da assistência técnica demandada pelo Ministério da Integração previa um programa-piloto no Nordeste
      Brasileiro para desenvolver ferramentas e estratégias de gestão proativa da seca, como um sistema de monitoramento
      e planos de preparação para as secas. Nesse sentido, as experiências dos Estados Unidos, México e Espanha
      contribuíram para o desenvolvimento de um Monitor de Secas no Brasil, que passou por adaptações metodológicas para
      se adequar à realidade da Região Nordeste, a primeira do País a ser contemplada devido ao histórico de secas
      intensas e recorrentes.</p>
    <p>Desde o seu lançamento, o Monitor de Secas fundamentou-se numa articulação contínua entre entidades estaduais e
      federais, que garante um processo 100% participativo, colaborativo e voluntário na elaboração dos mapas mensais de
      severidade de seca, característica que tem sido fundamental ao longo dos anos para a manutenção e o sucesso do
      Programa. Mais de 60 instituições colaboram em todo o País, compartilhando e analisando dados, traçando e
      validando mapas, observando os impactos no campo e promovendo a articulação entre diferentes setores e regiões.
    </p>
    <p>Inicialmente, apenas os nove estados do Nordeste estavam envolvidos na elaboração e validação mensal dos mapas.
      Sob coordenação da Fundação Cearense de Meteorologia e Recursos Hídricos (Funceme), o Monitor de Secas funcionou
      em caráter experimental, contando com três estados autores trabalhando no traçado do mapa: Bahia, Ceará e
      Pernambuco e todos os nove estados da região trabalhando na validação desse traçado. Somente em 2017, quando a ANA
      assume como instituição central coordenadora do processo, o Monitor passa para sua fase operacional, abrangendo
      articulação e treinamento de novos parceiros, documentação e melhorias dos processos, previsão de novas
      atividades, etc.</p>
    <p>A partir de 2018, a demanda por uma abordagem mais abrangente levou à expansão do Monitor de Secas, incluindo
      dados de estados contíguos ao Nordeste e em resposta às secas ocorridas em outras partes do Brasil. Minas Gerais
      foi o primeiro estado a aderir ao Monitor fora da região Nordeste, seguido por outros estados das regiões Sudeste,
      Sul, Centro-Oeste e Norte. Mesmo durante os anos de pandemia, o processo de expansão continuou, adaptando-se
      virtualmente às necessidades de isolamento social. Nesse período, as etapas para integrar um estado ao Monitor de
      Secas ocorreram 100% online, por meio de reuniões virtuais para mobilização, diagnóstico e treinamento das
      instituições parceiras. Em janeiro de 2024, completou-se a cobertura nacional, com a publicação do primeiro mapa
      de secas com o Amapá, último estado a aderir ao Monitor de Secas.</p>
    <p>Além da expansão territorial, com o intuito de fortalecer o processo de validação do mapa e propiciar a
      construção de um banco de dados sobre impactos de seca, criou-se o papel do Observador, ,que foi delegado a
      instituições estaduais parceiras com grande capilaridade e que podem fornecer informações de campo locais sobre os
      impactos decorrentes das secas. Com isso, a validação se beneficia, especialmente, com informações em áreas sem ou
      com fraco adensamento de rede de monitoramento, melhorando o traçado do mapa. Atualmente, o trabalho de autoria do
      mapa do Monitor está dividido em três blocos regionais: Nordeste (tendo Bahia, Ceará e Pernambuco como autores),
      Sul-Sudeste (Espírito Santo, Minas Gerais e Paraná como autores) e Norte-Centro-Oeste (com Goiás, Pará e Tocantins
      como autores). Todos os estados são validadores e as redes de observação estão em processo de estabelecimento nos
      estados.</p>
    <p>Em 2020, o Monitor de Secas foi instituído como um Programa da Agência Nacional de Águas e Saneamento Básico,
      visando fortalecer e garantir a sustentabilidade do projeto. Isso também impulsionou o processo de expansão,
      facilitando os vínculos e compromissos institucionais.</p>
    <p>Os treinamentos contínuos e a motivação das equipes estaduais, que foram parte da fase operacional do Monitor,
      revelaram-se fundamentais para a manutenção e o aprimoramento do processo. Da mesma forma, ao longo desses 10
      anos, foram realizadas adequações metodológicas e revisões de protocolos para melhor representar a seca em
      diferentes realidades e biomas, especialmente após a expansão do Monitor para todas as regiões brasileiras.</p>
    <p>Estes 10 anos foram marcados por muitos desafios, crescimento e aprendizados. Compreender melhor o comportamento
      da seca e seus impactos regionais, promover o uso do Monitor como instrumento de gestão e construir um banco de
      dados sobre os impactos observados são alguns dos objetivos atuais, que, quando alcançados tornarão o Monitor de
      Secas ainda mais representativo das realidades regionais e uma ferramenta eficaz no gerenciamento de secas.</p>
    <p>Esta jornada está apenas começando e nada disso seria possível sem essa vasta rede colaborativa de
      compartilhamento de dados e expertises. Estamos certos de que é essa parceria que faz o Monitor de Secas acontecer
      e é com ela que continuaremos a construir o histórico de secas no Brasil. Que venham mais 10 anos!</p>
    <p></p>
  </div>


    <app-galeria></app-galeria>

  </mat-card-content>

</mat-card>

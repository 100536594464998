import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-identidade-visual',
  templateUrl: './identidade-visual.component.html',
  styleUrls: ['./identidade-visual.component.css']
})
export class IdentidadeVisualComponent implements OnInit {
  
  constructor() { }

  ngOnInit() {
  }
}

export interface Mapa {
    id: number;
    nome: string;
    path: string;
    versao: string;
    data_criacao: Date;
    ano: number;
    mes:number;
    final: boolean;
    sumario: string;
    area: MapaArea[];
    descricao: MapaDescricao[];
    relatorio: MapaRelatorio[];
    shape: MapaShape[];
    cached_at: Date;
    queue_in: number;
    expires_in: number;
}

export const emptyMapa: Mapa = {
    id: null,
    nome: null,
    path: null,
    versao: null,
    data_criacao: null,
    ano: null,
    mes: null,
    final: null,
    sumario: null,
    area: null,
    descricao: null,
    relatorio: null,
    shape: null,
    cached_at: null,
    queue_in: null,
    expires_in: null
}

export interface MapaPayload {
    mes: number;
    ano: number;
}

export interface MapaArea {
    id: number,
    categoria: string,
    area: number,
    tipo_area: number,
    area_id: number,
    mapa_id: number
} 

export interface MapaDescricao {
    id: number,
    mapa_id: number,
    tipo_area: number,
    area: number,
    descricao: string
}

export interface MapaRelatorio {
    id: number,
    path: string,
    versao: string,
    mapa_id: number
}

export interface MapaShape {
    id: number,
    path: string,
    versao: string,
    mapa_id: number
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';

import { MaterialModule } from '@funceme/material';
import { CoreDataModule } from '@funceme/core-monitor';

import { LayoutModule } from '../layout/layout.module';
import { ComparacoesMapasComponent } from './comparacoes-mapas/comparacoes-mapas.component';
import { MapaRecenteComponent } from './mapa-recente/mapa-recente.component';
import { DadosTabularesComponent } from './dados-tabulares/dados-tabulares.component';
import { AlteracoesMensaisComponent } from './alteracoes-mensais/alteracoes-mensais.component';
import { AplicativosComponent } from './aplicativos/aplicativos.component';
import { CalendarioDeMapasComponent } from './calendario-de-mapas/calendario-de-mapas.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { IdentidadeVisualComponent } from './identidade-visual/identidade-visual.component';
import { InstituicoesParceirasComponent } from './instituicoes-parceiras/instituicoes-parceiras.component';
import { OMonitorDeSecasComponent } from './o-monitor-de-secas/o-monitor-de-secas.component';
import { PerguntasFrequentesComponent } from './perguntas-frequentes/perguntas-frequentes.component';
import { PublicacoesComponent } from './publicacoes/publicacoes.component';
import { TabelaDeClassificacaoDeSeveridadeDaSecaComponent } from './tabela-de-classificacao-de-severidade-da-seca/tabela-de-classificacao-de-severidade-da-seca.component';
import { ExpansaoMonitorComponent } from './expansao-monitor/expansao-monitor.component';
import { RouterModule } from '@angular/router';
import { DadosSIGComponent } from './dados-sig/dados-sig.component';
import { OrigemMonitorComponent } from './origem-monitor/origem-monitor.component';
import { AnimacoesMapasComponent } from './animacoes-mapas/animacoes-mapas.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';
import { ContatoComponent } from './contato/contato.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { UploadComponent } from './upload/upload.component';
import { TesteComponent } from './teste/teste.component';
import { BannerModalComponent } from '../layout/components/banner-modal/banner-modal.component';
import { XAnosComponent } from './x-anos/x-anos.component';
import { GaleriaComponent } from './galeria/galeria.component';
import { VideoXanosComponent } from './video-xanos/video-xanos.component';
import { QuemSomosComponent } from './quem-somos/quem-somos.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFilePdf, faFilePowerpoint, faFileArchive } from '@fortawesome/free-solid-svg-icons';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';


@NgModule({
  declarations: [
    HomeComponent,
    ComparacoesMapasComponent,
    MapaRecenteComponent,
    DadosTabularesComponent,
    AlteracoesMensaisComponent,
    AplicativosComponent,
    CalendarioDeMapasComponent,
    DownloadsComponent,
    IdentidadeVisualComponent,
    InstituicoesParceirasComponent,
    OMonitorDeSecasComponent,
    PerguntasFrequentesComponent,
    PublicacoesComponent,
    TabelaDeClassificacaoDeSeveridadeDaSecaComponent,
    ExpansaoMonitorComponent,
    DadosSIGComponent,
    OrigemMonitorComponent,
    AnimacoesMapasComponent,
    ContatoComponent,
    UploadComponent,
    TesteComponent,
    XAnosComponent,
    GaleriaComponent,
    VideoXanosComponent,
    QuemSomosComponent,
    PdfViewerComponent,
  ],
  imports: [
    CommonModule,
    CoreDataModule,
    LayoutModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    FontAwesomeModule
  ],
  entryComponents: [
    BannerModalComponent
  ],
  exports: [
    FontAwesomeModule
  ]
})
export class PagesModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faFilePdf, faFilePowerpoint, faFileArchive);
  }
}

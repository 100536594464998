<mat-card style="margin: 10px 0; ">


  <mat-card-content>
    <div class="container">
      <p class="text-justify">A Agência Nacional de Águas (ANA), como Instituição Central do Monitor, é responsável, pela
        gestão da sua
        identidade visual, cabendo-lhe a guarda, o envio e aprovação da aplicação do logotipo.</p>
      <p>Realize o download dos arquivos da identidade visual:</p>
      <ul>
        <li>

          <a class="btn-primary" href="https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/documentos/manual-de-identidade-visual-monitor-de-secas-2021.pdf"
            target="_blank"><fa-icon [icon]="['fas', 'file-pdf']"></fa-icon>Manual de Identidade Visual (PDF)</a>
        </li>
        <li>

          <a class="btn-primary" href="https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/documentos/modelo-ppt-monitor-de-secas2021.pptx"><fa-icon [icon]="['fas', 'file-powerpoint']"></fa-icon> Modelo
            de apresentação (PPTX)</a>
        </li>
        <li>

          <a class="btn-primary" href="https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/documentos/modeloantigo-ppt-monitor-de-secas.pptx"><fa-icon [icon]="['fas', 'file-powerpoint']"></fa-icon> Modelo Antigo
            de apresentação (PPTX)</a>
        </li>
        <li>

          <a class="btn-primary" href="https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/documentos/pacote-manual-de-identidade-visual-monitor-de-secas-2021.zip"><fa-icon [icon]="['fas', 'file-archive']"></fa-icon> Pacote
            completo de logos, modelo de slides e elementos visuais (ZIP).</a>
        </li>
      </ul>
      <p class="text-justify">
        <strong>Importante:</strong> Não está autorizado o uso da identidade do Monitor sem a prévia aprovação de parceiro envolvido no
        projeto. Em caso de dúvidas sobre a aplicação da identidade ou sobre as regras do Manual, favor encaminhar mensagem
        para <a href="mailto:digital@ana.gov.br?subject=EMAIL%20DIGITAL" target="_blank" title="EMAIL DIGITAL">digital@ana.gov.br</a>.
      </p>
    </div>

  </mat-card-content>
</mat-card>

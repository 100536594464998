<mat-card style="padding: 0px;">
    <mat-card-header>
        <mat-card-title>A inclusão gradual de outros Estados no Monitor de Secas</mat-card-title>

    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content>
      <div class="container">

        <h4><span>Por que os mapas disponíveis desde 2014 têm tamanhos diferentes?</span></h4>
        <p class="text-justify"><span>Em 2014, o Monitor de Secas iniciou suas atividades na Região Nordeste do país. A partir de 2018, com a metodologia e rotina operacional consolidadas, entendendo que todas as regiões do país são afetadas em maior ou menor grau por eventos de seca e constatando que o Monitor se tornou referência para o desenvolvimento e adoção de políticas públicas de combate à seca no Nordeste, iniciamos a ampliação gradual da área de abrangência do Monitor até alcançar uma cobertura nacional. Por isso, ao pesquisar mapas mais antigos na página principal do site, o usuário poderá notar que eles têm tamanhos diferentes, pois a área monitorada foi crescendo aos poucos, conforme os novos estados foram aderindo ao Programa.
        </span></p>

        <h4><span>Existiu um critério para escolher os estados que entraram primeiro e os que foram os últimos?</span></h4>
        <p class="text-justify"><span>Sim, o aspecto preponderante que norteou a logística de adesão dos estados ao Monitor de Secas foi o de continuidade territorial, ou seja, foram priorizados os estados que faziam divisa com a área já abrangida pelo Monitor;</span></p>

        <h4><span>E por que demorou 5 anos para chegar à cobertura nacional?</span></h4>
        <p class="text-justify"><span>Diversas etapas foram necessárias para transformar o Monitor de Secas do Nordeste no Monitor de Secas do Brasil: divulgação do Programa aos novos estados; prospecção, mobilização e articulação de parceiros estaduais; realização dos diagnósticos institucionais e de TI (para identificar o perfil das instituições e levantar os dados disponíveis para compartilhamento); incorporação das bases de monitoramento estaduais para utilização dos dados hidrometeorológicos; treinamentos específicos para cada um dos atores envolvidos com a rotina de elaboração dos mapas; e realização de ações coordenadas para integração do Monitor de Secas com as Salas de Situação Estaduais. Dessa forma, o tempo médio de inclusão de cada estado variou de 4 a 6 meses para completude de todas as etapas. Fazer 18 unidades federativas em 5 anos só foi possível porque a equipe da Instituição Central trabalhava pela incorporação de mais de um estado concomitantemente.</span></p>

        <h4 class="text-justify"><span>
          Quais os benefícios resultantes da expansão do Monitor para outros estados?
      </span></h4>

      <ul>
          <li class="text-justify"><span>
            A metodologia desenvolvida para o Nordeste vem sendo adequada às diversidades climáticas regionais do Brasil, melhorando a informação sobre a seca, sua disponibilização e utilização.
          </span></li>
          <li class="text-justify"><span>
            A cobertura em nível nacional representa a adoção de um modelo de Monitoramento de Secas uniforme no Brasil, cujo produto principal – o mapa mensal – pode ser usado como ferramenta de apoio à decisão e ao desenvolvimento de políticas públicas e programas governamentais de enfrentamento e combate às secas, melhorando a gestão e otimizando a aplicação de recursos.
          </span></li>
          <li class="text-justify"><span>
            Há um ganho notável no fortalecimento das relações interinstitucionais de todos os entes estaduais comprometidos com o Programa Monitor de Secas, que passam a compartilhar dados e informações de interesse mútuo.
          </span></li>
          <li class="text-justify"><span>
            Todos os dados disponíveis são reunidos numa base de dados unificada e aproveitados para gerar indicadores de seca e/ou produtos de apoio, que são analisados pelos autores dos mapas com o intuito de reproduzirem a situação de seca que mais se aproxime à realidade dos estados monitorados.
          </span></li>
      </ul>
      </div>

    </mat-card-content>
</mat-card>

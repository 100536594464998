<mat-card style="padding: 0px;">
  <mat-card-header>
    <mat-card-title>Quem somos</mat-card-title>

  </mat-card-header>

  <mat-divider></mat-divider>

  <mat-card-content>
    <app-instituicoes-parceiras></app-instituicoes-parceiras>

  </mat-card-content>
</mat-card>

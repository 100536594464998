import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContatoMonitorService {
  public base_url = environment.apiEndpoint;
  private urn = 'rpc/v1/contato-monitor';

  private serviceListener = new Subject<any>();
  public serviceListener$ = this.serviceListener.asObservable();

  constructor(private http: HttpClient) { }

  public get(params, callback?, errorHandler?): void {
    this.http.get(this.base_url + this.urn + '?nome=' + params.nome +
      '&email=' + params.email + '&telefone=' + params.telefone + '&mensagem=' + params.mensagem).subscribe((response) => {
        if (callback)
          callback(response);
        this.serviceListener.next(response);
      }, (error) => {
        if (errorHandler)
          errorHandler(error);
      });
  }
}

<mat-card style="padding: 0px;">
    <mat-card-header>
        <mat-card-title>PUBLICAÇÕES</mat-card-title>
        
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content class="content">

        <mat-card *ngFor="let publicacao of publicacoes" class="publicacao">
          <mat-card-content (click)="navigate(publicacao.url)">
            <div class="publicacao-img">
              <div style="width:100%">
                <img src={{publicacao.thumb}}>
              </div>
            </div>
            <div class="name"><strong>{{publicacao.nome}}</strong></div>
          </mat-card-content>
        </mat-card>
        
    </mat-card-content>
</mat-card>
<mat-sidenav-container class="example-container" (backdropClick)="close()">
  <mat-sidenav #sidenav (keydown.escape)="close()" disableClose>

    <button class="close-button" mat-button (click)="close()">
        <mat-icon style="color: #4E0001; padding: 0px 8px">clear</mat-icon>
    </button>

    <mat-divider></mat-divider>

      <button  mat-button (click)="link('mapa')">Mapa</button>

      <button mat-button [matMenuTriggerFor]="menuSobre">Sobre o Monitor</button> 
      <mat-menu #menuSobre="matMenu" yPosition="below">
        <button mat-menu-item (click)="link('o-monitor-de-secas')">O Monitor de Secas</button>
        <button mat-menu-item (click)="link('a-origem-do-monitor')">A origem do Monitor</button>
        <button mat-menu-item (click)="link('aplicativos')">Aplicativos</button>
        <button mat-menu-item (click)="link('calendario-de-mapas')">Calendário de Mapas</button>
        <button mat-menu-item (click)="link('expansao-do-monitor')">Expansão do Monitor</button>
        <button mat-menu-item (click)="link('identidade-visual')">Identidade visual</button>
        <button mat-menu-item (click)="link('instituicoes-parceiras')">Instituições parceiras</button>
        <button mat-menu-item (click)="link('publicacoes')">Publicações</button>
        <button mat-menu-item (click)="link('tabela-de-classificacao')">Classificação de severidade</button>
      </mat-menu>

      <button  mat-button (click)="link('contato')">Contato</button>

      <button mat-button [matMenuTriggerFor]="menuDadosMapas">Dados e Mapas</button> 
      <mat-menu #menuDadosMapas="matMenu" yPosition="below">
        <button mat-menu-item (click)="link('mapa')">Mapa mais recente</button>
        <button mat-menu-item (click)="link('alteracoes-mensais')">Alterações mensais</button>
        <button mat-menu-item (click)="link('animacoes')">Animações de mapas</button>
        <button mat-menu-item (click)="link('comparacoes-mapas')">Comparações de mapas</button>
        <button mat-menu-item (click)="link('dados-sig')">Dados SIG</button>
        <button mat-menu-item (click)="link('dados-tabulares')">Dados tabulares</button>
      </mat-menu>
      
      <button  mat-button (click)="link('perguntas-frequentes')">Perguntas frequentes</button>

      <a mat-button target="blank" href="http://funceme.br/app-zend-monitordesecas/">Acesso restrito</a>

    </mat-sidenav>

    <mat-sidenav-content>
      <div class="container-root">
        <app-header [sidenav]="sidenav" class="layout-header BgVerdeGrad mat-elevation-z2" ></app-header>
        <div class="layout-content">
            <app-navigation></app-navigation>
            <router-outlet></router-outlet>
        </div>
        <app-footer class="layout-footer"></app-footer>
      </div>
    </mat-sidenav-content>
</mat-sidenav-container>


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { NotificationsService } from '../../notifications/notifications.service';
import { MapaMudanca } from './mapa-mudanca.model';

@Injectable({
  providedIn: 'root'
})
export class MapaMudancaService {

  model = 'minio/get-files/msne';

  constructor(
    private http: HttpClient,
    private ns: NotificationsService
  ) { }

  getUrl() {
    return `${environment.apiEndpoint}${this.model}`;
  }

  all() {
    return this.http.get(environment.apiEndpoint+'rpc/v1/change_maps');

  }
}

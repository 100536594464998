import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendario-de-mapas',
  templateUrl: './calendario-de-mapas.component.html',
  styleUrls: ['./calendario-de-mapas.component.css']
})
export class CalendarioDeMapasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Projection } from 'ol/proj/Projection';
import { Component, OnInit } from '@angular/core';
import { RpcDadosTabularesService } from 'libs/core-monitor/src/lib/services/rpc-dados-tabulares.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as Highcharts from 'highcharts';
import * as XLSX from "xlsx";
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/annotations')(Highcharts);

declare var require: any;

@Component({
  selector: 'app-dados-tabulares',
  templateUrl: './dados-tabulares.component.html',
  styleUrls: ['./dados-tabulares.component.css']
})
export class DadosTabularesComponent implements OnInit {

  public escolherArea(){
    var registro;
    switch (this.selectedArea) {
      case 1:   registro = 'Centro-Oeste'; break;
      case 5:   registro = 'Nordeste'; break;
      case 3:   registro = 'Norte'; break;
      case 4:   registro = 'Sudeste'; break;
      case 2:   registro = 'Sul'; break;
    
      case 76:  registro = 'Brasil' ; break;

      case 12:  registro = 'Acre'; break;
      case 27:  registro = 'Alagoas'; break;
      case 16:  registro = 'Amapá'; break;
      case 13:  registro = 'Amazonas'; break;
      case 29:  registro = 'Bahia'; break;
      case 23:  registro = 'Ceará'; break;
      case 32:  registro = 'Espírito Santo'; break;
      case 21:  registro = 'Maranhão'; break;
      case 31:  registro = 'Minas Gerais'; break;
      case 15:  registro = 'Pará'; break;
      case 25:  registro = 'Paraíba'; break;
      case 26:  registro = 'Pernambuco'; break;
      case 22:  registro = 'Piauí'; break;
      case 24:  registro = 'Rio Grande do Norte'; break;
      case 28:  registro = 'Sergipe'; break;
      case 17:  registro = 'Tocantins'; break;
      case 14:  registro = 'Roraima' ; break ;  
      case 16:  registro = 'Amapá' ; break ; 
  
      case 53:  registro = 'Distrito Federal'; break;
      case 52:  registro = 'Goiás'; break;
      case 51:  registro = 'Mato Grosso'; break;
      case 50:  registro = 'Mato Grosso do Sul'; break;
      case 41:  registro = 'Paraná'; break;
      case 33:  registro = 'Rio de Janeiro'; break;
      case 43:  registro = 'Rio Grande do Sul'; break;
      case 11:  registro = 'Rondônia'; break;
      case 42:  registro = 'Santa Catarina'; break;
      case 35:  registro = 'São Paulo'; break;
    }
    return registro;
  }

  exportExcel() {
    const headers = ["mapas", "semSeca", "s0s4", "s1s4", "s2s4", "s3s4", "s4"];
    var aoo = this.tableData.map(function(obj) {
      return headers.reduce(function(acc, key) {
        acc[key] = obj[key];
        return acc;
      }, ({}));
    });

    const workSheet = XLSX.utils.json_to_sheet(aoo, {
      header: headers
    } );

    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'SheetName');
    XLSX.writeFile(workBook, 'DadosTabulares-acumulados-'+this.escolherArea()+'.xlsx');
}

  exportExcel2() {
    const headers = ["mapas", "semSeca", "s0", "s1", "s2", "s3", "s4"];
    var aoo = this.tableData2.map(function(obj) {
      return headers.reduce(function(acc, key) {
        acc[key] = obj[key];
        return acc;
      }, ({}));
    });

    const workSheet = XLSX.utils.json_to_sheet(aoo, {
      header: headers
    } );

    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'SheetName');
    XLSX.writeFile(workBook, 'DadosTabulares-isolados-'+this.escolherArea()+'.xlsx');
  }


  highcharts = Highcharts;
   chartOptions = {
      chart: {
         type: "area",
         plotBorderColor: '#C9C9C9',
         plotBorderWidth: 2,
      },
      colors: ['#FFFF00','#FCD37F','#FFAA00','#E60000','#730000'],
      title: {
         text: "Histórico dos dados tabulares de seca."
      },
      subtitle: {
          text: "Área Acumulada"
      },
      credits: {
          enabled: true,
          style: {
              color: '#4F4F4F',
          },
          text: '',
      },

      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              'viewFullscreen', "printChart", 'separator', 'downloadPNG',
              'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS'
            ]
          },
        },
        enabled: true,
      },
      navigation: {
        buttonOptions: {
          align: 'right',
          verticalAlign: 'top',
          y: 0
        }
      },

      xAxis:{
         categories:[],
         tickmarkPlacement: 'on',
         title: {
             enabled: false
         },
      },
      yAxis: {
         title:{
            text:"Porcentagem"
         },
         max: 100
      },
      tooltip: {
         valueSuffix:" %",
         shared: true,
      },
      plotOptions: {
          area: {
              lineColor: '#666666',
              lineWidth: 1,
              fillOpacity: 1,
              marker: {
                  enabled: false
              },
          },
      },
      series: [
         {
            name: 'S0-S4',
            data: []
         },
         {
            name: 'S1-S4',
            data: []
         },
         {
            name: 'S2-S4',
            data: []
         },
         {
            name: 'S3-S4',
            data: []
         },
         {
            name: 'S4',
            data: []
          }
      ]
   };
   chartOptions2 = {
    chart: {
       type: "area",
       plotBorderColor: '#C9C9C9',
       plotBorderWidth: 2,
    },
    colors: ['#FFFF00','#FCD37F','#FFAA00','#E60000','#730000'],
    title: {
       text: "Histórico dos dados tabulares de seca."
    },
    subtitle: {
        text: "Área Isolada"
    },
    credits: {
        enabled: true,
        style: {
            color: '#4F4F4F',
        },
        text: '',
    },

    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen', "printChart", 'separator', 'downloadPNG',
            'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS'
          ]
        },
      },
      enabled: true,
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        verticalAlign: 'top',
        y: 0
      }
    },

    xAxis:{
       categories:[],
       tickmarkPlacement: 'on',
       title: {
           enabled: false
       },
    },
    yAxis: {
       title:{
          text:"Porcentagem"
       },
       max: 100
    },
    tooltip: {
        valueSuffix:" %",
       shared: true,
    },
    plotOptions: {
        area: {
            stacking: 'normal',
            lineColor: '#666666',
            lineWidth: 1,
            fillOpacity: 1,
            marker: {
                enabled: false
            },
        },
    },
    series: [
       {
          name: 'S0',
          data: []
       },
       {
          name: 'S1',
          data: []
       },
       {
          name: 'S2',
          data: []
       },
       {
          name: 'S3',
          data: []
       },
       {
          name: 'S4',
          data: []
        }
    ]
 };

  public updateFlag = false;

  displayedColumns: string[] = ['mapa', 'sem seca', 'S0-S4', 'S1-S4', 'S2-S4', 'S3-S4', 'S4'];

  public loading: boolean = false;
  public routePriority: boolean = false;
  public subscriptions;

  public filesEndpoint = environment.filesEndpoint;

  public selectedAreaType;
  public selectedArea;
  public areas = [];
  public areaTypes = [
    {'id':1,'name':'UF'},
    {'id':9,'name':'País'},
    {'id':8,'name':'Região do Brasil'}];

  public regioesAreas = [
    {"id":1,"name":"Centro-Oeste"},
    {"id":5,"name":"Nordeste"},
    {"id":3,"name":"Norte"},
    {"id":4,"name":"Sudeste"},
    {"id":2,"name":"Sul"},
  ];

  public UfAreas = [
    {'id':12,'name':'Acre'},
    {'id':27,'name':'Alagoas'},
    {'id':16,'name':'Amapá'},
    {"id":13,"name":"Amazonas"},
    {'id':29,'name':'Bahia'},
    {'id':23,'name':'Ceará'},
    {'id':53,'name':'Distrito Federal'},
    {'id':32,'name':'Espírito Santo'},
    {'id':52,'name':'Goiás'},
    {'id':21,'name':'Maranhão'},
    {'id':51,'name':'Mato Grosso'},
    {'id':50,'name':'Mato Grosso do Sul'},
    {'id':31,'name':'Minas Gerais'},
    {'id':15,'name':'Pará'},
    {'id':25,'name':'Paraíba'},
    {'id':41,'name':'Paraná'},
    {'id':26,'name':'Pernambuco'},
    {'id':22,'name':'Piauí'},
    {'id':33,'name':'Rio de Janeiro'},
    {'id':24,'name':'Rio Grande do Norte'},
    {'id':43,'name':'Rio Grande do Sul'},
    {'id':11,'name':'Rondônia'},
    {'id':14,'name':'Roraima'},
    {'id':42,'name':'Santa Catarina'},
    {'id':35,'name':'São Paulo'},
    {'id':28,'name':'Sergipe'},
    {'id':17,'name':'Tocantins'}
  ];
   public paisesAreas=[
    {'id':76, 'name':'Brasil'}
    ];

  public tableData: {
    titulo:string,
    mapa:string,
    mapas:string,
    semSeca:number,
    s0s4:number,
    s1s4:number,
    s2s4:number,
    s3s4:number,
    s4:number
  }[] = [];

  public tableData2: {
    titulo:string,
    mapa:string,
    mapas:string,
    semSeca:number,
    s0:number,
    s1:number,
    s2:number,
    s3:number,
    s4:number
  }[] = [];

  constructor(private rpcDadosTabulares: RpcDadosTabularesService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
    this.subscriptions = this.rpcDadosTabulares.serviceListener$.subscribe(response => {

      if (!this.routePriority)
        this.syncRoute();
      else
        this.routePriority = false;

      this.chartOptions.xAxis.categories.length = 0;
      this.chartOptions.series.forEach(serie => serie.data.length = 0);

      this.chartOptions2.xAxis.categories.length = 0;
      this.chartOptions2.series.forEach(serie => serie.data.length = 0);

      response.data.list.forEach(element => {
        if (element.areas.length == 0) return;

        let tableElement = {
          titulo:'',
          mapa:'',
          mapas:'',
          semSeca:0,
          s0s4:0,
          s1s4:0,
          s2s4:0,
          s3s4:0,
          s4:0
        }
        let tableElement2 = {
          titulo:'',
          mapa:'',
          mapas:'',
          semSeca:0,
          s0:0,
          s1:0,
          s2:0,
          s3:0,
          s4:0
        }

        tableElement.mapa = element.mapa.path;
        tableElement.titulo = element.mapa.nome;
        tableElement.mapas = element.mapa.nome;

        tableElement2.mapa = element.mapa.path;
        tableElement2.titulo = element.mapa.nome;
        tableElement2.mapas = element.mapa.nome;

        this.chartOptions.xAxis.categories.unshift(element.mapa.nome);
        this.chartOptions2.xAxis.categories.unshift(element.mapa.nome);

        element.areas.forEach(area => {

          if (area.categoria == 'S0'){
            tableElement.semSeca = 100 - area.area/100;
            tableElement.s0s4 = area.area/100;

          } else if (area.categoria == 'S1')
            tableElement.s1s4 = area.area/100;

          else if (area.categoria == 'S2')
            tableElement.s2s4 = area.area/100;

          else if (area.categoria == 'S3')
            tableElement.s3s4 = area.area/100;

          else if (area.categoria == 'S4')
            tableElement.s4 = area.area/100;
        })

        tableElement2.semSeca =tableElement.semSeca;
        tableElement2.s0 = tableElement.s0s4-tableElement.s1s4;
        tableElement2.s1 = tableElement.s1s4-tableElement.s2s4;
        tableElement2.s2 = tableElement.s2s4-tableElement.s3s4;
        tableElement2.s3 = tableElement.s3s4-tableElement.s4;
        tableElement2.s4 = tableElement.s4;

        // console.log(tableElement2.s0.toFixed(2));

        this.tableData.push(tableElement);
        this.tableData2.push(tableElement2);

        this.tableData = [...this.tableData];
        this.tableData2 = [...this.tableData2];

        this.chartOptions.series[0].data.unshift(tableElement.s0s4);
        this.chartOptions.series[1].data.unshift(tableElement.s1s4);
        this.chartOptions.series[2].data.unshift(tableElement.s2s4);
        this.chartOptions.series[3].data.unshift(tableElement.s3s4);
        this.chartOptions.series[4].data.unshift(tableElement.s4);

        this.chartOptions2.series[0].data.unshift(parseFloat(tableElement2.s0.toFixed(2)));
        this.chartOptions2.series[1].data.unshift(parseFloat(tableElement2.s1.toFixed(2)));
        this.chartOptions2.series[2].data.unshift(parseFloat(tableElement2.s2.toFixed(2)));
        this.chartOptions2.series[3].data.unshift(parseFloat(tableElement2.s3.toFixed(2)));
        this.chartOptions2.series[4].data.unshift(parseFloat(tableElement2.s4.toFixed(2)));
      })

      this.updateFlag = true;
    })



    // this.subscriptions.add(
    //     this.http.get('https://api1.funceme.br/minio/download/msne?file=uploads/documentos/monitor_id_uf.txt').subscribe(
    //     data => {
    //       let idUfGroup = JSON.parse(data['file'])

    //       if (idUfGroup.length) {
    //         this.UfAreas = [];

    //         this.UfAreas = idUfGroup;

    //         if(this.selectedAreaType == 1)
    //           this.areas = [...this.UfAreas];
    //       }
    //     }
    //   ),
    //     this.http.get('https://api1.funceme.br/minio/download/msne?file=uploads/documentos/monitor_id_regioes.txt').subscribe(
    //     data => {
    //       let idRegioesGroup = JSON.parse(data['file'])

    //       if (idRegioesGroup.length) {
    //         this.regioesAreas = [];

    //         this.regioesAreas = idRegioesGroup;

    //         if(this.selectedAreaType == 8)
    //           this.areas = [...this.regioesAreas];
    //       }
    //     }
    //   )
    // );
  }

  ngOnInit() {
    this.selectedAreaType = 9;
    this.areas = [...this.paisesAreas];
    this.selectedArea = 76;

    const routeParams: Object = this.route.snapshot.queryParams;

    if (routeParams.hasOwnProperty('tipo') && routeParams.hasOwnProperty('area')) {
      this.routePriority = true;
      this.selectedAreaType = Number(routeParams['tipo']);
      this.selectedArea = Number(routeParams['area']);
      if(this.selectedAreaType == 1)
        this.areas = [...this.UfAreas];
      else if(this.selectedAreaType == 9)
        this.areas = [...this.paisesAreas];
      else
        this.areas = [...this.regioesAreas];
    }

    this.loading = true;
    this.rpcDadosTabulares
      .forTipoArea(this.selectedAreaType)
      .forArea(this.selectedArea)
      .get(() => {
        this.loading = false
      });
  }

  changedAreaType(event) {
    if(event.value == 1)
      this.areas = [...this.UfAreas];
    else if(event.value == 9)
      this.areas = [...this.paisesAreas];
    else
      this.areas = [...this.regioesAreas];
  }

  changedArea(event) {
    this.tableData.length = 0;
    this.tableData = [...this.tableData];

    this.tableData2.length = 0;
    this.tableData2 = [...this.tableData2];

    this.loading = true;
    this.rpcDadosTabulares
      .forTipoArea(this.selectedAreaType)
      .forArea(this.selectedArea)
      .get(() => {
        this.loading = false
      });
  }

  private syncRoute()
  {
    this.router.navigate([], {
      queryParams:{
        tipo: this.selectedAreaType,
        area: this.selectedArea
      },
      replaceUrl: true
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions)
      this.subscriptions.unsubscribe();
  }

}

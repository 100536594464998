import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expansao-monitor',
  templateUrl: './expansao-monitor.component.html',
  styleUrls: ['./expansao-monitor.component.css']
})
export class ExpansaoMonitorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

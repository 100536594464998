import { Component, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-x-anos',
  templateUrl: './x-anos.component.html',
  styleUrls: ['./x-anos.component.css']
})

export class XAnosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}


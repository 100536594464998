import { Component, OnInit } from '@angular/core';
import { Mapa, MapaFacade } from '@funceme/core-monitor';
import { emptyMapa } from 'libs/core-monitor/src/lib/state/mapa/mapa.model';
import { environment } from '@env/environment';

@Component({
  selector: 'app-mini-monitor',
  templateUrl: './mini-monitor.component.html',
  styleUrls: ['./mini-monitor.component.css']
})
export class MiniMonitorComponent implements OnInit {

  public mapa: Mapa = emptyMapa;
  public subscriptions;

  public filesEndpoint = environment.filesEndpoint;

  public popup: boolean = false;

  constructor(private mapaFacade: MapaFacade) {
    this.mapaFacade.loadMapaRecente();

    this.subscriptions = this.mapaFacade.currentMapa$.subscribe(value => {
      this.mapa = (value)? value : emptyMapa;
    })
  }

  ngOnInit() {
  }

  info() {
    this.popup = !this.popup
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { NotificationsService } from '../../notifications/notifications.service';
import { Uf } from './uf.model';
import { of } from 'rxjs';

import uf from './uf.json';

@Injectable({
  providedIn: 'root'
})
export class UfService {

  model = 'rest/adm/uf';

  constructor(
    private http: HttpClient,
    private ns: NotificationsService
  ) { }

  // getUrl() {
  //   return `${environment.apiEndpoint}${this.model}`;
  // }

  // getUrlForId(id) {
  //   let url = `${this.getUrl()}?id=${id}&limit=999`;
  //   return url;
  // }

  all() {
    // let ufResults = this.http.get<Uf[]>(this.getUrl() + '?orderBy=id&limit=999');
    const ufResults: Uf[] = uf
    return of(ufResults)

}

  // load(id) {
  //   return this.http.get<Uf>(this.getUrlForId(id));
  // }
}

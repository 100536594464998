import { Component, OnInit } from '@angular/core';
import parceiros from './instituicoes-parceiras.json';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-instituicoes-parceiras',
  templateUrl: './instituicoes-parceiras.component.html',
  styleUrls: ['./instituicoes-parceiras.component.css']
})
export class InstituicoesParceirasComponent implements OnInit {

  public parceiros: any[] = parceiros;
  public parceirosEstaduais = [];
  public parceirosFederais = [];
  public parceirosInternacionais = [];

  public subscriptions;


  ngOnInit() {
    this.updateList();
  }

  navigate(url) {
    window.open(url,"_blank");
  }

  updateList() {
    this.parceiros.sort((a, b) => {
      if (a.nome > b.nome) return 1;
      else return -1
    });

    this.parceirosEstaduais = [];
    this.parceirosFederais = [];
    this.parceirosInternacionais = [];

    this.parceiros.forEach((p:any) => {
      if( p.abrangencia == 'estadual') this.parceirosEstaduais.push(p);
      else if( p.abrangencia == 'federal') this.parceirosFederais.push(p);
      else this.parceirosInternacionais.push(p);
    });
  }

  // ngOnDestroy(): void {
  //   this.subscriptions.unsubscribe();
  // }
}

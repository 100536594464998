<a class=map href="http://monitordesecas.ana.gov.br/" target="_blank">
    <img *ngIf="mapa.path" src="{{filesEndpoint}}{{ mapa.path }}" />
</a>
<div class="icons">
    <a class="icon-expandir" href="http://monitordesecas.ana.gov.br/" target="_blank" title="Clique para Expandir"><mat-icon aria-hidden="false">zoom_out_map</mat-icon></a>
    <a id="info" *ngIf="!popup" title="Clique para obter Informações" style="float:right"><mat-icon (click)="info()" aria-hidden="false">info</mat-icon></a>
    <a id="info" *ngIf="popup" title="Clique para obter Informações" style="float:right"><mat-icon (click)="info()" aria-hidden="false">close</mat-icon></a>
<div style="clear: both"></div>
<mat-card id="popup" *ngIf="popup">
    O Monitor de Secas é um processo de acompanhamento regular e periódico da situação da seca, cujos resultados consolidados são divulgados por meio do Mapa do Monitor de Secas. Mensalmente informações sobre a situação de secas são disponibilizadas até o mês anterior, com indicadores que refletem o curto prazo (últimos 3, 4 e 6 meses) e o longo prazo (últimos 12, 18 e 24 meses), indicando a evolução da seca na região.
</mat-card>
<mat-card style="padding: 0px;">
    <mat-card-header>
        <mat-card-title>Aplicativos</mat-card-title>
        
    </mat-card-header>

    <mat-divider></mat-divider>
    
    <mat-card-content>
        <div class="container">
            <div style="width: 100%; text-align: center;">
                <img alt="" src="{{filesEndpoint}}uploads/img/flyer_monitor%281%29.jpg">
            </div>
        </div>
    </mat-card-content>
</mat-card>
<mat-card style="padding: 0px;">
  <mat-card-header>
    <mat-card-title>A origem do Monitor de Secas</mat-card-title>
      
  </mat-card-header>

  <mat-divider></mat-divider>
  
  <mat-card-content>
    <div class="container text-justify">
      <p>Em 2012, no auge de um período extremamente seco na Região Nordeste, o Ministério da Integração Nacional (MI) 
        criou um Grupo de Trabalho para avaliar a política e as abordagens de gestão brasileiras relativas às secas, 
        assim como para estudar e apresentar subsídios para o desenho de uma Política Nacional de Secas. À época, as 
        propostas do referido Grupo de Trabalho se juntaram às discussões que vinham ocorrendo no cenário internacional 
        e que tiveram ápice durante o Encontro de Alto Nível sobre Políticas Nacionais de Seca, ocorrido em Genebra em 
        março de 2013 (High Level Meeting on National Drought Policies - HMNDP), no qual o Brasil, representado pelo MI, 
        declarou seu compromisso em discutir e debater como desenhar, coordenar e integrar uma política abrangente 
        para planejamento e gestão de secas, de forma a reduzir os impactos, ou seja, aumentar a resiliência às secas 
        futuras e às mudanças climáticas.</p>
      
      <p>A partir do encontro ocorrido em Genebra, o Ministério da Integração Nacional solicitou apoio ao Banco Mundial 
        na construção de uma assistência técnica – intitulada de “Preparação para as secas e resiliência às mudanças 
        climáticas”, que englobasse o diálogo e o arcabouço necessários ao desenvolvimento de políticas nacional e 
        estaduais sobre secas e um programa piloto regional para o Nordeste brasileiro. Com essa iniciativa, vislumbrava-se 
        uma mudança de paradigma no tratamento dado à gestão das secas no território brasileiro, passando de uma gestão 
        de crise (ou reativa) a uma gestão de risco (ou pro-ativa). Essa assistência técnica também contou com o apoio 
        do Fundo Espanhol para a América Latina e Caribe (SFLAC) e do Water Partnership Program (WPP).</p>
      
      <p>Nessa perspectiva, a abordagem proposta para lidar com as secas era suportada em três pilares: (1) monitoramento, 
        alerta precoce e rede de previsão meteorológica; (2) melhor identificação e conhecimento da vulnerabilidade, da 
        resiliência e dos impactos das secas na região Nordeste; e (3) planejamento de resposta mais coordenado e 
        sistemático, com desenvolvimento de uma estratégia de mitigação de longo prazo.</p>
      
      <p>Durante seminário realizado em Fortaleza em outubro de 2013, intitulado "Secas, Impactos e Respostas: uma Análise 
        da Seca de 2012-2013 no Nordeste e Contribuições para uma Política Nacional de Seca”, representantes de várias 
        instituições federais, estaduais e organizações civis concordaram em desenvolver uma metodologia de monitoramento 
        e uma rede de informações para melhorar a previsão e o alerta precoce de secas na região Nordeste, nascendo aí o 
        Monitor de Secas.</p>
      
      <p>Ao longo de 2014, um grupo multissetorial de mais de 40 instituições federais e estaduais, representadas por cerca 
        de 80 profissionais, participou de oficinas de desenvolvimento do Monitor de Secas e de outras medidas de 
        preparação para as secas no Nordeste. Para tanto, por meio de assistência técnica do Banco Mundial, contou-se com 
        parceiros nacionais e internacionais, com ampla experiência no monitoramento de secas, que ajudaram desenvolver 
        e implementar a fase experimental do Monitor de Secas, entre eles ressalta-se a participação do 
        Centro Nacional de Mitigação de Secas (NDMC) da Universidade de Nebraska/EUA e da Comissão Nacional de Águas do 
        México (CONAGUA).</p>
      
      <p>Em agosto de 2014, o Monitor de Secas entrou em fase experimental, sob a liderança, na esfera federal, da Agência 
        Nacional de Águas (ANA), do Ministério da Integração Nacional (MI) e do Instituto Nacional de Meteorologia (INMET). 
        Na esfera estadual o Monitor de Secas conta com o envolvimento de todos os Estados do Nordeste, disponibilizando 
        informações e participando do processo de validação do mapa do Monitor, por meio da Secretaria de Meio Ambiente e 
        Recursos Hídricos (SEMARH) do Estado de Alagoas; da  Empresa Baiana de Desenvolvimento Agrícola (EBDA) e do 
        Instituto de Meio Ambiente e Recursos Hídricos (INEMA) do Estado da Bahia; da Companhia de Gestão dos Recursos 
        Hídricos (COGERH), da Fundação Cearense de Meteorologia e Recursos Hídricos (FUNCEME) e da Secretaria de 
        Desenvolvimento Agrário (SDA) do Estado do Ceará; do Núcleo Geoambiental da Universidade Estadual do Maranhão 
        (UEMA/NUGEO) do Estado do Maranhão; da Agência de Defesa e Fiscalização Agropecuária de Pernambuco (ADAGRO), 
        da Agência Pernambucana de Águas e Clima (APAC), da Companhia Pernambucana de Saneamento (COMPESA) e do Instituto 
        Agronômico de Pernambuco (IPA) do Estado de Pernambuco; da Empresa de Assistência Técnica e Extensão Rural 
        (EMATER) e da Secretaria de Meio Ambiente e Recursos Hídricos (SEMAR) do Estado do Piauí; da Agência Executiva 
        de Gestão das Águas (AESA) do Estado da Paraíba; da Empresa de Assistência Técnica e Extensão Rural (EMATER) e da 
        Empresa de Pesquisa Agropecuária (EMPARN) do Estado do Rio Grande do Norte; da Companhia de Saneamento de Sergipe 
        (DESO) e da Secretaria de Meio Ambiente e Recursos Hídricos (SEMARH) do Estado de Sergipe. Todos esses atores 
        mantêm articulação permanente em torno do processo do Monitor de Secas, sob a   liderança compartilhada do INEMA, 
        da FUNCEME e da APAC que são as instituições estaduais que exercem a função de autores dos mapas do Monitor.</p>
      
      <p>Ao longo do processo de desenvolvimento do Monitor de Secas, a ANA foi identificada como a instituição 
        que reunia condições para assumir a coordenação técnica e operacional do Monitor e dar sustentabilidade à 
        implementação e ao aprimoramento do monitoramento de secas. Nesse sentido, no segundo semestre de 2015, 
        os arranjos institucionais e operacionais envolvidos no Monitor de Secas passaram a ser lastreados por um Acordo 
        de Cooperação Técnica entre a ANA, o MI e o INMET e Acordos de Cooperação entre a ANA e todos os estados do 
        Nordeste com vistas a implementação do Monitor de Secas.</p>
      </div>
  </mat-card-content>
</mat-card>
import { Component, OnInit } from '@angular/core';
import { UfFacade, Uf, Mapa, MapaFacade } from '@funceme/core-monitor';
import { zip } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { emptyMapa } from 'libs/core-monitor/src/lib/state/mapa/mapa.model';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-mapa-recente',
  templateUrl: './mapa-recente.component.html',
  styleUrls: ['./mapa-recente.component.css']
})
export class MapaRecenteComponent implements OnInit {

  public mapa: Mapa = emptyMapa;
  public ufs: Uf[];
  public ufsSelecionados = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
  public mapasEstados:any[] = [];
  public alert_text;

  public filesEndpoint = environment.filesEndpoint;

  public hasPrevious: boolean = true;
  public hasNext: boolean = true;

  public loading: boolean = false;

  public routePriority: boolean = false;

  private finalMes;
  private finalAno;
  public allPeriodos = [];
  public selectedMapa;
  public lastMap: Mapa;
  public lastMapSubscription;
  public subscriptions;

  constructor(
    private ufFacade: UfFacade,
    private mapaFacade: MapaFacade,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    this.mapaFacade.selectMapa(null);
    this.mapaFacade.loadMapaRecente();

    this.lastMapSubscription = this.mapaFacade.currentMapa$.subscribe(value => {
      if (!value.ano) return;
      this.lastMap = value;

      this.finalAno = this.lastMap.ano;
      this.finalMes = this.lastMap.mes + 1;

      if (this.finalMes == 13){
        this.finalMes = 1;
        this.finalAno++;
      }

      this.setAllPeriodos();
      this.selectedMapa = this.allPeriodos[0];

      const routeParams: Object = this.route.snapshot.queryParams;

      this.ufFacade.loadUfs();

      if (routeParams.hasOwnProperty('mes') && routeParams.hasOwnProperty('ano')) {
        this.routePriority = true;
        this.selectedMapa = routeParams['mes']+"/"+routeParams['ano'];
        this.mapaFacade.pickMapa(routeParams['mes'], routeParams['ano']);
      } else
        this.mapaFacade.loadMapaRecente();
    });

    this.subscriptions = this.mapaFacade.currentMapa$.subscribe(value => {
      this.mapa = (value)? value : emptyMapa;

      if ((this.ufs && this.mapa.descricao)) {
        this.selectedMapa = this.mapa.mes+"/"+this.mapa.ano;
        this.makeMapasEstados();
        if (!this.routePriority)
          this.syncRoute();
        else
          this.routePriority = false;
      }
    });

    this.subscriptions.add(
        this.http.get(environment.apiEndpoint+'rpc/v1/disclaimer').subscribe(
        data => this.alert_text = this.sanitizer.bypassSecurityTrustHtml(data['file'])
      )
    );

    this.subscriptions.add(
      this.ufFacade.allUfs$.subscribe(value => this.ufs = (value)? value : []),
      this.mapaFacade.loading$.subscribe(value => this.loading = value),
      zip (
        this.mapaFacade.currentMapa$,
        this.ufFacade.allUfs$
      ).subscribe(
        ([currentMapa, ufs]) =>{
          if (currentMapa.descricao)
            this.makeMapasEstados();
        })
    );
  }

  public ngOnInit()
  {

  }

  private makeMapasEstados()
  {
    this.mapasEstados = [];


    this.ufsSelecionados.forEach(uf => {
      let filter_of_uf = this.ufs.filter(value => value.id == uf);
      let _monitor = [];
      if (filter_of_uf.length > 0)
      {
        let geocod = Number(filter_of_uf[0].geocod);
        _monitor = this.mapa.descricao.filter(value => this.ufs.length && value.tipo_area == 1 && value.area == geocod);
      }


      if (_monitor.length)
        this.mapasEstados.push({
          nome: this.ufs.filter(value => value.id == uf)[0].nome,
          sumario: _monitor[0].descricao
        });
    });
  }

  private syncRoute()
  {
    this.router.navigate([], {
      queryParams:{
        mes: this.mapa.mes,
        ano: this.mapa.ano
      },
      replaceUrl: true
    });
  }

  public back()
  {
    let ano = this.mapa.ano;
    let mes = this.mapa.mes - 1;

    if (mes == 0) {
      mes = 12;
      ano = ano - 1;
    } else if (mes == 6 && ano == 2014) {
      mes = this.lastMap.mes;
      ano = this.lastMap.ano;
    }

    this.mapaFacade.pickMapa(mes, ano);
  }

  public forward()
  {
    let ano = this.mapa.ano;
    let mes = this.mapa.mes + 1;

    if (mes == 13) {
      mes = 1;
      ano = ano + 1;
    }
    if (mes == this.finalMes && ano == this.finalAno) {
      mes = 7;
      ano = 2014;
    }

    this.mapaFacade.pickMapa(mes, ano);
  }

  public download() {
    window.open(this.filesEndpoint+this.mapa.relatorio[0].path);
  }

  public changedMapa(event) {
    let [mes, ano] = event.value.split('/')
    this.mapaFacade.pickMapa(mes, ano);
  }

  public setAllPeriodos() {
    let year = this.lastMap.ano;
    let month = this.lastMap.mes;
    let c = 100;
    while (year != 2014 || month != 6){
      this.allPeriodos.push(month+"/"+year);
      month--;
      if (month == 0) {
        month = 12;
        year--;
      }
    }

    this.lastMapSubscription.unsubscribe()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

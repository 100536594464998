
<div *ngIf="alert_text && alert_text !== null" class="alert alert-primary" [innerHTML]="alert_text"></div>

<!-- <div *ngIf="alert_text !== ''; else elseBlock"> -->
  <!-- <p>Condição verdadeira.</p> -->
<!-- </div> -->
<!--  -->
<!-- <ng-template #elseBlock> -->
  <!-- <p>Condição falsa.</p> -->
<!-- </ng-template> -->
<!--  -->


<mat-card style="padding: 0px;">
    <mat-card-header>
        <mat-card-title>Mapa mais recente</mat-card-title>

        <button *ngIf="hasPrevious" class="hide-if-mobile" mat-button (click)="back()" style="position: absolute; right: 260px; top: 7px; color: #E85D01;">
            <mat-icon>arrow_back_ios</mat-icon> Anterior
        </button>
        <button *ngIf="hasPrevious" class="hide-if-desktop" mat-icon-button (click)="back()" style="position: absolute; right: 90px; top: 2px; color: #E85D01;">
            <mat-icon>arrow_back_ios</mat-icon>
        </button>

        <button *ngIf="hasNext" class="hide-if-mobile" mat-button (click)="forward()" style="position: absolute; right: 140px; top: 7px; color: #E85D01;">
             Próximo <mat-icon>arrow_forward_ios</mat-icon>
        </button>
        <button *ngIf="hasNext" class="hide-if-desktop" mat-icon-button (click)="forward()" style="position: absolute; right: 50px; top: 2px; color: #E85D01;">
            <mat-icon>arrow_forward_ios</mat-icon>
       </button>

        <button mat-button class="hide-if-mobile" (click)="download()" style="position: absolute; right: 10px; top: 7px; color: #fff; background-color: #E85D01;" matTooltip="Fazer o download da Síntese do traçado">
            <mat-icon>cloud_download</mat-icon> Download
        </button>
        <button mat-icon-button class="hide-if-desktop" (click)="download()" style="border-radius: 5px; position: absolute; right: 10px; top: 2px; color: #fff; background-color: #E85D01;" matTooltip="Fazer o download da Síntese do traçado">
            <mat-icon>cloud_download</mat-icon>
        </button>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-progress-bar mode="indeterminate" [class.show]="loading" [class.hide]="!loading"></mat-progress-bar>

    <mat-card-content>
        <div class="container">
            <div class="item-monitor">
                <div class="selector">
                    <mat-form-field>
                        <mat-select [value]="selectedMapa" (selectionChange)="changedMapa($event)">
                            <mat-option *ngFor="let periodo of allPeriodos" [value]="periodo">
                                {{ periodo }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class=map>
                    <img *ngIf="mapa.path" src="{{filesEndpoint}}{{ mapa.path }}" class="img-responsive" />
                </div>
            </div>
            <div class="item-release">
                <mat-accordion>
                    <mat-expansion-panel expanded="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Sumário
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div [innerHtml]="mapa.sumario"></div>
                    </mat-expansion-panel>

                    <mat-expansion-panel *ngFor="let mapaEstado of mapasEstados">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ mapaEstado.nome }}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div [innerHtml]="mapaEstado.sumario"></div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </mat-card-content>
</mat-card>

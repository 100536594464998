import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// Importe os módulos do Font Awesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from '@funceme/material';
import { LayoutModule } from '@angular/cdk/layout';
import { PagesModule } from './pages/pages.module';
import { CoreDataModule } from '@funceme/core-monitor';
import { AuthModule } from 'libs/core-monitor/src/lib/auth/auth.module';
import { NxModule } from '@nrwl/nx';
import { environment } from '@env/environment.prod';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthInterceptor } from 'libs/core-monitor/src/lib/auth/auth.interceptor';
import { MiniMonitorComponent } from './mini/mini-monitor/mini-monitor.component';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [AppComponent, MiniMonitorComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    LayoutModule,
    PagesModule,
    CoreDataModule,
    AuthModule,
    FontAwesomeModule,
    NxModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
  }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    // Adicione os ícones que você deseja usar ao library
    library.add(fas);
  }
 }

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MapaMudancaFacade, MapaMudanca } from '@funceme/core-monitor';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-alteracoes-mensais',
  templateUrl: './alteracoes-mensais.component.html',
  styleUrls: ['./alteracoes-mensais.component.css']
})
export class AlteracoesMensaisComponent implements OnInit {

  dates = [];

  public loading: boolean = false;
  private dataMapaMaisRecente: Date = null;
  private dataMapaMaisAntigo = new Date('2014-07-31');

  public selectedDateFrom = null;
  public selectedDateTo = null;

  public dateFromOptions: string[] = [];
  public dateToOptions: string[] = [];

  public mapaMudanca: MapaMudanca;

  public routePriority: boolean = true;

  constructor(
    private mapaMudancaFacade: MapaMudancaFacade,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.mapaMudancaFacade.loading$.subscribe(value => this.loading = value);

    this.mapaMudancaFacade.currentMapaMudanca$.subscribe(value => this.mapaMudanca = value);

    this.mapaMudancaFacade.allMapaMudancas$.subscribe(value => {

      value.map(value => {
        const data = new Date(value.data);
        if (!this.dataMapaMaisRecente || data > this.dataMapaMaisRecente)
          this.dataMapaMaisRecente = data;
      });

      this.dateFromOptions = [];
      this.dateToOptions = [];

      let opcao: Date = new Date(this.dataMapaMaisRecente);

      opcao.setDate(1);
      opcao.setMonth(opcao.getMonth());

      while (opcao > this.dataMapaMaisAntigo) {
        this.dateFromOptions.push(new Date(opcao).toISOString().slice(0, 7));
        opcao = new Date(opcao.setMonth(opcao.getMonth() - 1));
      }

      this.dateToOptions = this.dateFromOptions.slice(1, this.dateFromOptions.length);

      const routeParams: Object = this.route.snapshot.queryParams;

      if (this.routePriority 
        && routeParams.hasOwnProperty('dataDe') 
        && routeParams.hasOwnProperty('dataAte')
        && this.dateFromOptions.indexOf(routeParams['dataDe']) !== -1
        && this.dateToOptions.indexOf(routeParams['dataAte']) !== -1
      ) {
        this.selectedDateFrom = routeParams['dataDe'];
        this.selectedDateTo = routeParams['dataAte'];
      } else {
        this.selectedDateFrom = this.dateFromOptions[0];
        this.selectedDateTo = this.dateToOptions[0];

        this.routePriority = false;
      }

      this.changedDate();
    });
  }

  ngOnInit() {
    this.mapaMudancaFacade.loadMapaMudancas();
  }

  private syncRoute() 
  {
    this.router.navigate([], {
      queryParams:{
        dataDe: this.selectedDateFrom,
        dataAte: this.selectedDateTo
      },
      replaceUrl: true
    });
  }

  changedDate() 
  {
    this.dateToOptions = this.dateFromOptions.slice(this.dateFromOptions.indexOf(this.selectedDateFrom)+1, this.dateFromOptions.length);

    if (this.dateToOptions.indexOf(this.selectedDateTo) === -1)
      this.selectedDateTo = this.dateToOptions[0];

    let dateFrom = new Date(this.selectedDateFrom + '-01');
    let dateTo = new Date(this.selectedDateTo + '-01');

    let differenceMonths = dateTo.getMonth() - dateFrom.getMonth();
    let differenceYears = dateTo.getFullYear() - dateFrom.getFullYear();

    let difference = Math.abs(differenceMonths + differenceYears * 12);

    this.syncRoute();

    this.mapaMudancaFacade.selectMapaMudanca(this.selectedDateFrom + '_' + difference + 'm');
  }
}

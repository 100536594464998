<mat-card style="padding: 0px;">
  <mat-card-header>
      <mat-card-title>Alterações Mensais</mat-card-title>
  
  </mat-card-header>

  <mat-divider></mat-divider>
  
  <mat-progress-bar mode="indeterminate" [class.show]="loading" [class.hide]="!loading"></mat-progress-bar>

  <mat-card-content>
      <div class="container">
        <!-- <h4>Data do produto</h4> -->
        <div class="item">

            <mat-form-field class="alteracao-mensal">
              <mat-label>Data do produto</mat-label>
              <mat-select [(value)]="selectedDateFrom" (selectionChange)="changedDate()">
                <mat-option *ngFor="let date of dateFromOptions" [value]="date">
                  {{ date }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="alteracao-mensal" >
              <mat-label>Data de comparacao</mat-label>
              <mat-select [(value)]="selectedDateTo" (selectionChange)="changedDate()">
                <mat-option *ngFor="let date of dateToOptions" [value]="date">
                  {{ date }}
                </mat-option>
              </mat-select>
            </mat-form-field>

        </div>
        <br />
        <div class="item" *ngIf="mapaMudanca && mapaMudanca.path">
          <img src="https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/{{ mapaMudanca.path }}" style="max-width: 800px">
        </div>        
      </div>
  </mat-card-content>
</mat-card>

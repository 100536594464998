import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { RootComponent } from './layout/root/root.component';
import { MapaRecenteComponent } from './pages/mapa-recente/mapa-recente.component';
import { DadosTabularesComponent } from './pages/dados-tabulares/dados-tabulares.component';
import { AlteracoesMensaisComponent } from './pages/alteracoes-mensais/alteracoes-mensais.component';
import { AplicativosComponent } from './pages/aplicativos/aplicativos.component';
import { CalendarioDeMapasComponent } from './pages/calendario-de-mapas/calendario-de-mapas.component';
import { DownloadsComponent } from './pages/downloads/downloads.component';
import { IdentidadeVisualComponent } from './pages/identidade-visual/identidade-visual.component';
import { OMonitorDeSecasComponent } from './pages/o-monitor-de-secas/o-monitor-de-secas.component';
import { PerguntasFrequentesComponent } from './pages/perguntas-frequentes/perguntas-frequentes.component';
import { PublicacoesComponent } from './pages/publicacoes/publicacoes.component';
import { TabelaDeClassificacaoDeSeveridadeDaSecaComponent } from './pages/tabela-de-classificacao-de-severidade-da-seca/tabela-de-classificacao-de-severidade-da-seca.component';
import { InstituicoesParceirasComponent } from './pages/instituicoes-parceiras/instituicoes-parceiras.component';
import { ExpansaoMonitorComponent } from './pages/expansao-monitor/expansao-monitor.component';
import { ComparacoesMapasComponent } from './pages/comparacoes-mapas/comparacoes-mapas.component';
import { LoginComponent } from 'libs/core-monitor/src/lib/auth/login/login.component';
import { authConfig } from '@env/environment';
import { DadosSIGComponent } from './pages/dados-sig/dados-sig.component';
import { OrigemMonitorComponent } from './pages/origem-monitor/origem-monitor.component';
import { AnimacoesMapasComponent } from './pages/animacoes-mapas/animacoes-mapas.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { MiniMonitorComponent } from './mini/mini-monitor/mini-monitor.component';
import { UploadComponent } from './pages/upload/upload.component';
import { TesteComponent } from './pages/teste/teste.component';
import { XAnosComponent } from './pages/x-anos/x-anos.component';
import { QuemSomosComponent } from './pages/quem-somos/quem-somos.component';

const routes: Routes = [
  {
    path: '',
    component: RootComponent,
    children:  [
      {
        path: '',
        redirectTo: authConfig.route_default,
        pathMatch: 'full'
      },

      { path: 'login',                        component: LoginComponent },
      { path: 'mapa',                         component: MapaRecenteComponent },
      { path: 'dados-tabulares',              component: DadosTabularesComponent },
      { path: 'dados-sig',                    component: DadosSIGComponent },
      { path: 'alteracoes-mensais',           component: AlteracoesMensaisComponent },
      { path: 'animacoes',                    component: AnimacoesMapasComponent },
      { path: 'aplicativos',                  component: AplicativosComponent },
      { path: 'calendario-de-mapas',          component: CalendarioDeMapasComponent },
      { path: 'downloads',                    component: DownloadsComponent },
      { path: 'identidade-visual',            component: IdentidadeVisualComponent },
      { path: 'o-monitor-de-secas',           component: OMonitorDeSecasComponent },
      { path: 'a-origem-do-monitor',          component: OrigemMonitorComponent },
      { path: 'perguntas-frequentes',         component: PerguntasFrequentesComponent },
      { path: 'publicacoes',                  component: PublicacoesComponent },
      { path: 'instituicoes-parceiras',       component: InstituicoesParceirasComponent },
      { path: 'comparacoes-mapas',            component: ComparacoesMapasComponent },
      { path: 'expansao-do-monitor',          component: ExpansaoMonitorComponent },
      { path: 'tabela-de-classificacao',      component: TabelaDeClassificacaoDeSeveridadeDaSecaComponent },
      { path: 'contato',                      component: ContatoComponent },
      { path: 'upload',                       component: UploadComponent },
      { path: 'teste',                        component: TesteComponent },
      { path: 'x-anos',                       component: XAnosComponent },
      { path: 'quem-somos',                   component: QuemSomosComponent },

    ]
  },
  {
    path: 'mini',
    component: MiniMonitorComponent
  },
  {
    path: '**',
    redirectTo: 'mapa'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import * as fromMapa from './mapa.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { emptyMapa } from './mapa.model';

export const selectMapaState = createFeatureSelector<fromMapa.MapaState>('mapa');

export const selectedMapaId = createSelector(
  selectMapaState,
  fromMapa.getSelectedMapaId
);

export const selectMapaEntities = createSelector(
  selectMapaState,
  fromMapa.selectMapaEntities
);

export const selectAllMapas = createSelector(
  selectMapaState,
  fromMapa.selectAllMapas
);

export const selectCurrentMapaId = createSelector(
  selectMapaState,
  fromMapa.getSelectedMapaId
);

export const selectLoadingMapa = createSelector(
  selectMapaState,
  fromMapa.getLoadingMapa
);

export const selectCurrentMapa = createSelector(
  selectMapaState,
  selectCurrentMapaId,
  (mapaEntities, mapaId) => {
    return mapaId ? mapaEntities.entities[mapaId] : emptyMapa;
  }
);

import { Action } from '@ngrx/store';
import { Mapa, MapaPayload } from './mapa.model';

export enum MapaActionTypes {
  MapasAction = '[Mapas] Action',
  LoadMapas = '[Mapas] Load Data',
  PickMapa = '[Mapas] Pick Data',
  MapasLoaded = '[Mapas] Data Loaded',
  MapaSelected = '[Mapas] Selected',
  MapaAdded = '[Mapas] Added',
  LoadLastMapa = '[Mapas] Load Recent',
}

export class Mapas implements Action {
  readonly type = MapaActionTypes.MapasAction;
}

export class LoadMapas implements Action {
  readonly type = MapaActionTypes.LoadMapas;
  constructor() { }
}

export class MapasLoaded implements Action {
  readonly type = MapaActionTypes.MapasLoaded;
  constructor(public payload: Mapa[]) { }
}

export class MapaSelected implements Action {
  readonly type = MapaActionTypes.MapaSelected;
  constructor(public payload: number) { }
}

export class PickMapa implements Action {
  readonly type = MapaActionTypes.PickMapa;
  constructor(public payload: MapaPayload) { }
}

export class MapaAdded implements Action {
  readonly type = MapaActionTypes.MapaAdded;
  constructor(public payload: Mapa) { }
}

export class LoadLastMapa implements Action {
  readonly type = MapaActionTypes.LoadLastMapa;
  constructor() { 
  }
}

export type MapaActions = Mapas
  | LoadMapas
  | MapasLoaded
  | MapaSelected
  | PickMapa
  | MapaAdded
  | LoadLastMapa
;
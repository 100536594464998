import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from 'libs/core-monitor/src/lib/notifications/notifications.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {

  public loading: boolean = false;
  public registerForm: FormGroup;
  public submitted = false;

  private file: File | null = null;

  @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
    const file = event && event.item(0);
    this.file = file;
  }

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private notifyService: NotificationsService
  ) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      file: ['', Validators.required]
    });
  }

  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.requiredFileType()
    if (this.registerForm.invalid || !this.requiredFileType()) {
      // console.log("error");
      return false;
    }
    this.loading = true;

    this.sendFile();
    this.onReset();
  }

  onReset() {
    this.registerForm.reset();
    this.submitted = null;
  }

  requiredFileType() {
    let file = this.file;

    if ( file ) {
      const extension = file.name.split('.').slice(-1)[0].toLowerCase();

      if ( extension.toLowerCase() != 'exe' && extension.toLowerCase() != 'dll') {

        return true;
      }
      this.notifyService.emit('arquivo não permitido');
      this.onReset();
      return false;
    };
    return false;
  }

  private sendFile(){
    const formData = new FormData();
    formData.append('file', this.file);

    // this.http.post('https://apil5.funceme.br/minio/upload-file/msne', formData)
    // this.http.post('https://api1.funceme.br/minio/upload-file/msne', formData)
    //   .subscribe(res => {
    //     this.notifyService.emit('Arquivo enviado com sucesso.');
    //     this.loading = false;

    //   },error => {
    //     this.notifyService.emit('Error:');
    //     this.loading = false;
    //   })
  }
}

import { Injectable } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import * as MapaActions from './mapa.actions';
import { MapaState } from './mapa.reducer';
import { selectAllMapas, selectCurrentMapa, selectLoadingMapa } from './mapa.selectors';

@Injectable({ providedIn: 'root' })
export class MapaFacade {
  
  allMapas$ = this.store.pipe(select(selectAllMapas));
  currentMapa$ = this.store.pipe(select(selectCurrentMapa));
  loading$ = this.store.pipe(select(selectLoadingMapa));

  constructor(private store: Store<MapaState>, private actions$: ActionsSubject) {}

  selectMapa(mapaId: number) {
    this.store.dispatch(new MapaActions.MapaSelected(mapaId));
  }

  loadMapas() {
    this.store.dispatch(new MapaActions.LoadMapas());
  }

  pickMapa(mes: number, ano: number) {
    this.store.dispatch(new MapaActions.PickMapa({mes: mes, ano: ano}));
  }

  loadMapaRecente() {
    this.store.dispatch(new MapaActions.LoadLastMapa());
  }
}

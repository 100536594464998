<header>
    <div class="side-menu-bar">
        <button mat-button (click)="sidenav.open()" style="padding: 15px; float: left">
          <mat-icon style="color: white">menu</mat-icon>
        </button>  
        <div style="height: 60px">
          <a [routerLink]="['/']">
            <img style="max-width: 55%; max-height: 70%; height: auto; padding: 10px 0px" src="assets/img/logo_nome_horizontal_branco.png">
          </a>
          <img style="max-width: 8%; max-height: 75%; height: auto; padding: 8px 20px" class="mapa" src="https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/monitor_brasil_150.png"/>
        </div>
    </div>
    <div class="container-header top-menu-bar">
        
        <a [routerLink]="['/']" class="item-logo">
          <img src="assets/img/logo_nome_horizontal_branco.png" height="80px" width="285px" />
        </a>
        <div class="item-brasil">
          <img class="mapa" src="https://ana-monitor-secas-files.s3.sa-east-1.amazonaws.com/uploads/img/monitor_brasil_150.png" height="80px" />
        </div>
    </div>
  </header>

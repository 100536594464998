<mat-card style="padding: 0px;">
   <mat-card-header>
    <mat-card-title>Dados Tabulares</mat-card-title>
      
  </mat-card-header>
  <mat-progress-bar mode="indeterminate" [class.show]="loading" [class.hide]="!loading"></mat-progress-bar>
  <mat-divider></mat-divider>
  
  <mat-progress-bar mode="indeterminate" [class.show]="loading" [class.hide]="!loading"></mat-progress-bar>

  <mat-tab-group>
    <mat-tab label="Área Acumulada">
      <mat-card-content>
        <div class="container">
          <div class="selector">
              <mat-form-field>
                  <mat-label>Selecione o tipo</mat-label>
                  <mat-select [(ngModel)]="selectedAreaType" (selectionChange)="changedAreaType($event)">
                      <mat-option *ngFor="let type of areaTypes" [value]="type.id">
                          {{ type.name }}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
              <mat-form-field>
                  <mat-label>Selecione a area</mat-label>
                  <mat-select [(ngModel)]="selectedArea" (selectionChange)="changedArea($event)">
                      <mat-option *ngFor="let area of areas" [value]="area.id">
                          {{ area.name }}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </div>

          <!-- <div id="grafico" style="min-width: 310px; height: 500px; margin: 0 auto">grafico</div> -->
          <highcharts-chart
            [Highcharts] = "highcharts"
            [options] = "chartOptions"
            [(update)]= "updateFlag"
            style = "padding: 0 80px 0 0 ;min-width: 300px; height: 500px; display: block; margin: 0 auto">
          </highcharts-chart>

          <button mat-raised-button color="warn" (click)="exportExcel()">Exportar Planilha</button>

          <div class="table-hover" #TABLE>
            <mat-table [dataSource]="tableData" class="mat-elevation-z8">
              <ng-container matColumnDef="mapa">
                  <mat-header-cell *matHeaderCellDef> Mapa </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <a href="{{filesEndpoint}}{{element.mapa}}" target="_blank">{{element.titulo}}</a>
                  </mat-cell>
              </ng-container>

              <ng-container matColumnDef="sem seca">
                  <mat-header-cell *matHeaderCellDef> Sem seca </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.semSeca | number:'1.0-2'}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="S0-S4">
                  <mat-header-cell *matHeaderCellDef> S0-S4 </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.s0s4}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="S1-S4">
                  <mat-header-cell *matHeaderCellDef> S1-S4 </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.s1s4}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="S2-S4">
                  <mat-header-cell *matHeaderCellDef> S2-S4 </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.s2s4}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="S3-S4">
                  <mat-header-cell *matHeaderCellDef> S3-S4 </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.s3s4}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="S4">
                  <mat-header-cell *matHeaderCellDef> S4 </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.s4}} </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
          </div>
        </div>
      </mat-card-content>

    </mat-tab>

    <mat-tab label="Área Isolada">

      <mat-card-content >
        <div class="container">
          <div class="selector">
              <mat-form-field>
                  <mat-label>Selecione o tipo</mat-label>
                  <mat-select [(ngModel)]="selectedAreaType" (selectionChange)="changedAreaType($event)">
                      <mat-option *ngFor="let type of areaTypes" [value]="type.id">
                          {{ type.name }}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
              <mat-form-field>
                  <mat-label>Selecione a area</mat-label>
                  <mat-select [(ngModel)]="selectedArea" (selectionChange)="changedArea($event)">
                      <mat-option *ngFor="let area of areas" [value]="area.id">
                          {{ area.name }}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </div>

          <!-- <div id="grafico" style="min-width: 310px; height: 500px; margin: 0 auto">grafico</div> -->
          <highcharts-chart
            [Highcharts] = "highcharts"
            [options] = "chartOptions2"
            [(update)]= "updateFlag"
            style = "padding: 0 80px 0 0;min-width: 300px; height: 500px; display: block; margin: 0 auto">
          </highcharts-chart>

          <button mat-raised-button color="warn" (click)="exportExcel2()">Exportar Planilha</button>

          <div class="table-hover">
            <mat-table [dataSource]="tableData2" class="mat-elevation-z8">
              <ng-container matColumnDef="mapa">
                  <mat-header-cell *matHeaderCellDef> Mapa </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <a href="{{filesEndpoint}}{{element.mapa}}" target="_blank">{{element.titulo}}</a>
                  </mat-cell>
              </ng-container>

              <ng-container matColumnDef="sem seca">
                  <mat-header-cell *matHeaderCellDef> Sem seca </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.semSeca | number:'1.0-2'}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="S0-S4">
                  <mat-header-cell *matHeaderCellDef> S0 </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.s0 | number:'1.0-2'}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="S1-S4">
                  <mat-header-cell *matHeaderCellDef> S1 </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.s1 | number:'1.0-2'}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="S2-S4">
                  <mat-header-cell *matHeaderCellDef> S2 </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.s2 | number:'1.0-2'}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="S3-S4">
                  <mat-header-cell *matHeaderCellDef> S3 </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.s3 | number:'1.0-2'}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="S4">
                  <mat-header-cell *matHeaderCellDef> S4 </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.s4}} </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
          </div>
        </div>
      </mat-card-content>

    </mat-tab>

  </mat-tab-group>






</mat-card>
